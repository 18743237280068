.grid-container {
  width: 100%;
  margin: 0;
  overflow-y: hidden;
  .fixed-min-width {
    min-width: 100px;
  }
  .custom-data-table {
    font-size: 15px;
    min-width: 100%;
    overflow-y: hidden;
    .custyle {
      width: 100%;
      margin-top: 15px;
      .custab {
        width: 100%;
        .table-head {
          color: #0059b3;
          font-size: 13px;
          font-weight: bold;
          text-align: center;
          .head-row {
            font-weight: bold;
            text-align: left;
            height: 40px;
            line-height: 18px;
          }
          th {
            &:nth-child(2) {
              width: 160px;
            }
          }
          .machine-head-row {
            th {
              &:nth-child(4) {
                width: 150px;
              }
            }
          }
        }
        .machine-row {
          td {
            &:nth-child(4) {
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                font-weight: bold;
              }
            }
          }
        }
      }
      h2 {
        font-weight: 700;
        color: #4d5a77;
        float: left;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
    }
    .table-row-client {
      font-size: 12px;
      color: #0059b3;
      height: 40px;
      cursor: pointer;
      &:hover {
        background: rgba(4, 160, 222, 0.23);
      }
    }
    .table-row {
      font-size: 12px;
      color: #0059b3;
      height: 40px;
    }
    .border-row {
      border: 1px solid #ddd;
      text-align: center;
      padding: 10px;
      .add-edit {
        margin: 0 5px;
        cursor: pointer;
        font-size: 16px;
        .fa-trash {
          font-size: 16px;
          color:#e14335;
        }
        .fa-window-close-o {
          font-size: 16px;
          color: #e14335;
        }
        .fa-check-square {
          font-size: 16px;
          color: #7ab675;
        }
      }
      .show-cursor {
        cursor: pointer;
        text-decoration: underline;
        &:hover {
          font-weight: bold;
        }
      }
    }
    .min-action-width {
      min-width: 110px;
    }
    .align-text-left {
      text-align: left;
      max-width: 150px;
      word-wrap: break-word;
    }
  }
}

.show-cursor {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    font-weight: bold;
  }
}