.upadated-table-conatainer {
    height: auto;
    margin-top: 10px;
    .table-container {
        min-width: 100%;
        overflow-y: hidden;
        padding: 10px;
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
        table {
            width: 100%;
            thead {
                tr {
                    // border: 1px solid rgba(0,0,0,.125);
                    // box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
                    th {
                        border: 1px solid rgba(0,0,0,.125);
                        word-wrap: break-word;
                        text-align: left;
                        padding: 10px;
                        .table-headers {
                            color: var(--table-font-primary-color);
                            font-size: 13px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            tbody {
                tr {
                    // border: 1px solid rgba(0,0,0,.125);
                    // box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
                    td {
                        border: 1px solid rgba(0,0,0,.125);
                        word-wrap: break-word;
                        color: var(--table-font-primary-color);
                        text-align: left;
                        padding: 10px;
                        .table-data-inner {
                            // padding: 7px;
                        }

                        .text-link {
                            color: #285eb8;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .completed {
                            background: #02695D;
                            border-radius: 4px;
                            color: white;
                            padding: 6px;
                        }
                        .inProgress {
                            background: #C8850B;
                            border-radius: 4px;
                            color: white;
                            padding: 6px;
                        }
                        .warning {
                            color: red;
                        }
                        .failed {
                            background: #C31515;
                            border-radius: 4px;
                            color: white;
                            padding: 6px;
                        }
                    }
                    .action-row {
                        min-width: 120px;
                        color: var(--updated-primary-color);
                        .add-edit {
                            margin-right: 5px;
                            margin-left: 5px;
                            font-size: 18px;
                            cursor: pointer;
                        }
                        .delete {
                            margin-right: 5px;
                            margin-left: 5px;
                            color: red;
                            font-size: 18px;
                            cursor: pointer;
                        }
                    }
                }
                & :hover {
                    background-color: #f5f5f5;
                }
            }

            .no-hover-effect {
                & :hover {
                    background-color: white;
                }
            }

            .action-field {
                width: 150px;
                max-width: 150px;
                min-width: 150px;
            }
            
            .global-width {
                width: 100px;
                max-width: 100px;
                min-width: 100px;
            }

            .column-width-30 {
                width: 30px;
                max-width: 30px;
                min-width: 30px;
            }
            
            .column-width-40 {
                width: 40px;
                max-width: 40px;
                min-width: 40px;
            }

            .column-width-50 {
                width: 50px;
                max-width: 50px;
                min-width: 50px;
            }

            .column-width-60 {
                width: 60px;
                max-width: 60px;
                min-width: 60px;
            }
            
            .column-width-70 {
                width: 70px;
                max-width: 70px;
                min-width: 70px;
            }
            
            .column-width-80 {
                width: 80px;
                max-width: 80px;
                min-width: 80px;
            }
            
            .column-width-85 {
                width: 85px;
                max-width: 85px;
                min-width: 85px;
            }
            
            .column-width-90 {
                width: 90px;
                max-width: 90px;
                min-width: 90px;
            }
            
            .column-width-95 {
                width: 95px;
                max-width: 95px;
                min-width: 95px;
            }
            
            .column-width-100 {
                width: 100px;
                max-width: 100px;
                min-width: 100px;
            }
            
            .column-min-width-100 {
                min-width: 100px;
            }

            .column-width-110 {
                width: 110px;
                max-width: 110px;
                min-width: 110px;
            }

            .column-width-min-110 {
                min-width: 110px;
            }
            
            .column-width-115 {
                width: 115px;
                max-width: 115px;
                min-width: 115px;
            }
            
            .column-width-120 {
                width: 120px;
                max-width: 120px;
                min-width: 120px;
            }

            .column-width-min-120 {
                min-width: 120px;
            }

            .column-width-125 {
                width: 125px;
                max-width: 125px;
                min-width: 125px;
            }

            .column-width-130 {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
            }

            .column-width-min-130 {
                min-width: 130px;
            }
            
            .column-width-135 {
                width: 135px;
                max-width: 135px;
                min-width: 135px;
            }

            .column-width-140 {
                width: 140px;
                max-width: 140px;
                min-width: 140px;
            }

            .column-width-150 {
                width: 150px;
                max-width: 150px;
                min-width: 150px;
            }

            .column-width-160 {
                width: 160px;
                max-width: 160px;
                min-width: 160px;
            }

            .column-width-165 {
                width: 165px;
                max-width: 165px;
                min-width: 165px;
            }

            .column-min-width-165 {
                min-width: 165px;
            }

            .column-width-170 {
                width: 170px;
                max-width: 170px;
                min-width: 170px;
            }

            .column-width-175 {
                width: 175px;
                max-width: 175px;
                min-width: 175px;
            }

            .column-min-width-175 {
                min-width: 175px;
            }
            
            .column-width-180 {
                width: 180px;
                max-width: 180px;
                min-width: 180px;
            }
            .column-width-185 {
                width: 185px;
                max-width: 185px;
                min-width: 185px;
            }

            .column-width-200 {
                width: 200px;
                max-width: 200px;
                min-width: 200px;
            }

            .column-min-width-200 {
                min-width: 200px;
            }

            .column-width-210 {
                width: 210px;
                max-width: 210px;
                min-width: 210px;
            }

            .column-width-240 {
                width: 240px;
                max-width: 240px;
                min-width: 240px;
            }

            .column-min-width-300 {
                min-width: 300px;
            }
            
            .text-input {
                text-transform: capitalize;
            }

            
        }
        .no-data-found {
            margin-top: 10px;
            text-align: center;
            color: var(--table-font-primary-color);
        }
    }

    .updated-pagination-controler {
        margin-top: 20px;

        .pagination-btn {
            margin-right: 3px;
            width: 110px;
        }
    }
}