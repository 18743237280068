$font-family: 'MyriadProLight", "PT Sans';
$disable-submit-color: #636C73;
$signin-link-color: darkslategray;
$selected-menu-color: rgb(225, 67, 58);
$selected-menu: rgba(34, 45, 50, 0.37);
$white-bg: #fff;
$btn-color:#195C94;
$tab-bottom-color:#e14335;
$addon-background:  #ffff;
$sidebar-background: rgb(255, 255, 255);
$transperant-background: rgba(255, 255, 255, 0.72);
$box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$trash-color:  #e14335;
$button-color: #a22e2a;
$button-cancel-color: #a9abad;
$addon-color:  #464a4c;
$border-radius:3px;
$border-universal-color: #E0E0E0;
$side-menu-link-color: var(--updated-backgroud-linear-gradient) !important;

$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 66px;
$sidebar-width: 200px;

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '/assets';
$images-root: '/img/';
$fonts-root: '/fonts/';
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-bolder: 500;
$font-ultraBold: 900;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #fff;

$table-color: #0059b3;
$table-border-color: #ddd;
