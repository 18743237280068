.label {
  border-radius: 0;
}

.label-primary {
  background: $primary;
}

.label-info {
  background: $primary-light;
}

.label-success {
  background: $success;
}

.label-warning {
  background: $warning;
}

.label-danger {
  background: $danger;
}


.form-group {
  label {
    margin-bottom: 5px;
    color: $content-text;
    font-weight: $font-normal;
    font-size: 13px;
  }
}

.form-control {
  //color: $content-text;
  @include placeholderStyle($content-text, 0.7);
  //border: 1px solid $input-border;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.9);
  //background-color: $input-background;
  box-shadow: none;
  font-size: 14px;
  &:focus {
    //color: $content-text;
    box-shadow: none;
    border-color: var(--updated-primary-color) !important;
    //background: $input-background;
  }
}

select.form-control {
  padding-left: 8px;
}

select.form-control:not([multiple]) option {
  color: $dropdown-text;
}

select.form-control[multiple] option {
  color: $default-text;
}

textarea.form-control {
  height: 96px;
}

.form-inline {
  .form-group {
    input {
      width: 100%;
    }
    label {
      margin-right: 12px;
    }
  }

  button[type="submit"] {
    margin-left: 12px;
  }
}

@mixin setSwitchBorder($color) {
  .bootstrap-switch.bootstrap-switch-on {
    border-color: $color;
  }
}

.switch-container {
  display: inline-block;
  &.primary {
    @include setSwitchBorder($primary);
  }
  &.success {
    @include setSwitchBorder($success);
  }
  &.warning {
    @include setSwitchBorder($warning);
  }
  &.danger {
    @include setSwitchBorder($danger);
  }
  &.info {
    @include setSwitchBorder($primary-light);
  }
}

.bootstrap-switch {
  border-radius: 5px;
  border: 1px solid $default;
  transition: border-color ease-in-out .7s, box-shadow ease-in-out .7s;
  &:focus {
    outline: none;
  }
  &.bootstrap-switch-off {
    border-color: $border;
  }
  &.bootstrap-switch-focused {
    box-shadow: none;
    &.bootstrap-switch-off {
      border-color: $border;
    }
  }
  .bootstrap-switch-container {
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }
  .bootstrap-switch-handle-on {
    border-radius: 0;
    &.bootstrap-switch-default {
      background: $default;
    }
    &.bootstrap-switch-success {
      background: $success;
    }
    &.bootstrap-switch-primary {
      background: $primary;
    }
    &.bootstrap-switch-warning {
      background: $warning;
    }
    &.bootstrap-switch-danger {
      background: $danger;
    }
    &.bootstrap-switch-info {
      background: $primary-light;
    }
  }
  .bootstrap-switch-handle-off {
    border-radius: 0;
  }

  .bootstrap-switch-label {
    background: transparent;
  }

  &.bootstrap-switch-animate .bootstrap-switch-container {
    transition: margin-left .2s;
  }
}

.switches {
  margin-left: -12px;
  margin-bottom: -12px;
  .switch-container {
    float: left;
    margin-left: 12px;
    margin-bottom: 12px;
  }
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
  & > span {
    border-radius: 0;
  }
}


.nowrap {
  white-space: nowrap;
}

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


@mixin customInput($color) {
  & > span {
    &:before {
      color: $color;
    }
    &:hover {
      &:before {
        border-color: $color;
      }
    }
  }
}

label.custom-input-primary {
  @include customInput($primary);
}

label.custom-input-success {
  @include customInput($success);
}

label.custom-input-warning {
  @include customInput($warning)
}

label.custom-input-danger {
  @include customInput($danger)
}

.form-horizontal {
  .radio, .radio-inline {
    padding-top: 0;
  }
}

.input-demo {
  line-height: 25px;
}

@mixin validationState($color, $focusColor) {
  .control-label {
    color: $content-text;
  }
  .form-control {
    border: 1px solid $color;
    &:focus {
      box-shadow: none;
      border-color: $focusColor;
    }
  }
  label.custom-checkbox {
    color: $color;
    & > span {
      &:before {
        color: $color;
      }
      &:hover {
        &:before {
          border-color: $color;
        }
      }
    }
  }
  .form-control-feedback {
    color: $color;
  }
  .input-group-addon {
    background-color: $color;
    color: $label-text;
  }
}

.input-group-addon {
  line-height: inherit;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-feedback {
  .form-control {
    padding-right: 42.5px;
  }
  label ~ .form-control-feedback {
    top: 19px;
    font-size: 18px;
  }
}

.bootstrap-select {
  .btn-default {
    &:focus {
      color: $default-text;
    }
  }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: $input-background;
  color: $disabled;
  border-color: $disabled-bg;
  @include placeholderStyle($default-text, 0.5);
}

.form-control-rounded {
  border-radius: 16px;
}

.help-block {
  color: $help-text;
  vertical-align: sub;
}

.raiting-box {
  display: flex;
}

.help-block.error-block {
  display: none;
  .has-error &.basic-block {
    display: block;
  }
}

@mixin groupAddon($color) {
  background: $color;
  color: $label-text;
  border-color: $color;
}

.input-group-addon-danger {
  @include groupAddon($danger);
}

.input-group-addon-warning {
  @include groupAddon($warning);
}

.input-group-addon-success {
  @include groupAddon($success);
}

.input-group-addon-primary {
  @include groupAddon($primary);
}

.checkbox-demo-row {
  margin-bottom: 12px;
}

.dropdown-menu {
  border-radius: 5px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: $content-text;
}


.has-success {
  @include validationState($success-bg, $success);
  position: relative;
}

.has-warning {
  @include validationState($warning-bg, $warning);
  position: relative;
}

.has-error {
  @include validationState($danger-bg, $danger);
  position: relative;
}

.bootstrap-tagsinput {
  color: $content-text;
  background-color: $input-background;
  border: 1px solid $input-border;
  border-radius: 5px;
  box-shadow: none;
  max-width: 100%;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  &.form-control {
    display: block;
    width: 100%;
  }
  .tag {
    border-radius: 3px;
    font-weight: $font-normal;
    font-size: 11px;
    padding: 4px 8px;
    & [data-role="remove"]:hover {
      box-shadow: none;
    }
  }
  input {
    background-color: $input-background;
    border: 1px solid $input-border;
    border-radius: 5px;
    @include placeholderStyle($default-text, 0.8);
    line-height: 22px;
    font-size: 11px;
    min-width: 53px;
  }
}

.progress {
  background: $progress-background;
}

.progress-bar-primary {
  background-color: $primary;
}

.progress-bar-success {
  background-color: $success-light;
}

.progress-bar-warning {
  background-color: $warning;
}

.progress-bar-danger {
  background-color: $danger;
}

.has-success .input-group-addon {
  border: none;
}

.input-group > span.addon-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group > span.addon-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group-btn {

  &:not(:first-child) {
    > .btn, > .btn-group {
      margin-left: 0;
    }
  }

  > .btn {
    line-height: 1.56;
  }
}

.with-primary-addon:focus {
  border-color: $primary;
}

.with-warning-addon:focus {
  border-color: $warning;
}

.with-success-addon:focus {
  border-color: $success;
}

.with-danger-addon:focus {
  border-color: $danger;
}

.sub-little-text {
  font-size: 12px;
}

.rating {
  font-size: 20px;
}

rating-inputs span {
  vertical-align: middle;
}

