@import "./app/theme/sass/auth";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "./app/shared/new-libraries-style/ngx-smart-modal/ngx-smart-modal.scss";
@import "./app/shared/updatedTable.scss";

@import "./app/pages/users/users.scss";
@import "./app/pages/users/user-form/user.component.scss";
@import "./app/pages/users/child-users/child-users.component.scss";
@import "./app/shared/machine-breakdown/machine-breakdown.component.scss";
@import "./app/pages/machines/machines.component.scss";
@import "./app/pages/machines/machine-form/machine-form.component.scss";
@import "./app/pages/dashboard/dashboard.scss";
@import "./app/pages/geographies/geography-form/geography-form.component.scss";
@import "./app/pages/farmer/farmer.component.scss";

html {
  overflow-x: hidden;
}

:root{
  --updated-primary-color: #23852B !important;
  --updated-backgroud-linear-gradient: linear-gradient(88.37deg, #015423 -26.83%, #024E45 -26.83%, #23852B 162.22%, #15681C 162.22%) !important;
  --header-title-color: rgba(0, 0, 0, 0.7) !important;
  --note-text-color: #333333 !important;
  --global-btn-radious : 8px !important;
  --global-radious: 8px;
  --drop-down-check-box-color:  #1C1B1F;
  --table-font-primary-color:#333333;
  --updated-font-family : 'Poppins';
}

body {
  width: 100%;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 14px;
  top: 0 !important;
}

main {
  &::before {
    position: initial;
  }
}

#pagination, #paginationOperator, #paginationAssignMachine, #paginationUnAssignMachine, #machinePagination, #expensePagination, #childPagination,
#allMachinepagination, #allBreakDownRequests {
  padding: 0 0;
  display: inline-block;
  margin: 10px 0;
  border-radius: 2px;
  ul {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  ul li {
    border: 1px solid #d6d6d6;
    margin-left: -2px;
    height: 35px;
    text-align: center;
    min-width: 35px;
    line-height: 27px;
    &:hover {
      background: transparent;
    }
  }
  .ngx-pagination {
    .current {
      background: var(--updated-primary-color) !important;
      color: #fff;
    }
    .pagination-previous {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
    .pagination-next {
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    a {
      color: #606c71;
      &:hover {
        background: transparent;
      }
    }
    .disabled {
      padding: 0.1875rem 0.625rem;
      margin-left: 0.9px;
      color: #cacaca;
      cursor: not-allowed;
    }
  }
}

.btn:hover {
  transform: none;
}

//for input box
.inputbox {
  border-radius: $border-radius;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  padding-left: 2%;
  border: 1px solid gainsboro;
  outline: none;
  box-shadow: none;
  color: #555555;
}

.inputbox::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: gainsboro;
}

.inputbox::-moz-placeholder { /* Firefox 19+ */
  color: gainsboro;
}

.inputbox:-ms-input-placeholder { /* IE 10+ */
  color: gainsboro;
}

.inputbox:-moz-placeholder { /* Firefox 18- */
  color: gainsboro;
}

.inputbox:focus, .inputbox:active {
  border-color: #A5C9BB;
  outline: 0;
  outline: thin dotted \9
;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(82, 168, 236, .6);
  box-shadow: inset 0 1px 1px rgba(0, 220, 0, .075), 0 0 8px rgba(0, 225, 0, .2);
}

.input-button {
  height: auto;
  line-height: 2.8;
  margin: 3% 0 -2% 0;
}

//for btn style
.btn-style {
  width: 100%;
  height: 100%;
  background: var(--updated-backgroud-linear-gradient) !important ;
  border-radius: 8px !important;
  color: #ffff !important;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.btn-style-fixed {
  width: 150px;
  height: 35px;
  background: #FFFFFF !important;
  border: 1px solid var(--updated-primary-color) !important;
  border-radius: 8px !important;
  color: var(--updated-primary-color) !important;
  font-size: 15px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
 }

.btn-cancel-style {
  background: #FFFFFF !important;
  border: 1px solid var(--updated-primary-color) !important;
  border-radius: 8px !important;
  color: var(--updated-primary-color) !important;
  font-weight: bold;
  cursor: pointer;
  width: auto;
  font-size: 12px;
  min-width: 150px;
  height: auto;
  &:focus {
    outline: none;
  }
}

.back-arrow {
  font-size: 30px;
}

.back-report-arrow {
  float: left;
  padding: 7px 10px 0 0;
}

.password-error-message {
  float: left;
  margin-top: -35px;
  width: 100%;
  margin-bottom: 10px;
}

.capitalise {
  text-transform: capitalize;
}

.fixed-header {
  min-width: 110px;
}

.hand-pointer {
  cursor: pointer;
}

.required {
  color: red;
}

.date-padding-top {
  padding-top: 10px;
}

.multi-line {
  white-space: pre-line;
  position: absolute;
  line-height: 40px;
  font-weight: 400;
}

.fa-male {
  font-size: 20px;
}

.gmnoprint .gm-style-mtc{
  display: none;
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserActiveContainer .modal-dialog {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
}

.common-container, .booking-main, .user-container, .farmer-main {
  .confirmUserActiveContainer {
    button.close {
      position: relative;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
    .modal-header {
      display: none;
    }
    .modal-footer {
      display: none;
    }
  }
}

.filters-drop-style {
  .input-group.search-container {
    padding: 5px;
    .input-group-prepend {
      //padding: 0;
    }
  }
  .button.btn.btn-default {
    padding: 7px !important;
  }
}

#products, .filters-drop-style, #productsList, #products-option {
  .left-align {
    text-align: left !important;
  }
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    float: right;
    top: 6px;
    position: relative;
    height: 0;
    margin-left: 0;
    vertical-align: middle;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
  }
  .input-group-prepend {
    padding: 6px;
    background: lightgrey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  button.btn.btn-default {
    border-width: 1px;
    color: #ffffff;
    border-radius: 0;
    padding: 8px;
    background: #5f5f5f;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .dropdown-menu {
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 0;
  }

  .search-container {
    padding: 10px 5px 5px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 999;
  }

  .dropdown-item {
    line-height: 1;
    border-bottom: 1px solid rgba(128, 128, 128, 0.43);
    padding: 8px 1.5rem;
  }

  .empty {
    text-align: center;
    padding-bottom: 15px;
  }
}

#products-option {
  .dropdown-menu {
    width: 100% !important;
  }
}

.bill-image {
  display: flex;
  justify-content: center;
  line-height: 0;
  align-items: center;
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    z-index: -1;
  }
  .inputfile-1 + label {
    color: $white-bg;
    background-color: $button-color;
  }

  .inputfile-2 + label {
    color: $white-bg;
    background-color: $button-color;
  }

  .inputfile-3 + label {
    color: $white-bg;
    background-color: #e14335;
  }

  .inputfile + label {
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
  }
  .button-space {
    padding: 6px;
    margin-right: 10px;
  }
  .button-space-machine {
    padding: 8px;
  }
  .upload-doc {
    width: 40px;
    padding: 8px;
    margin-right: 5px;
    text-align: center;
  }
  .view-file{
    width: 40px;
  }
  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
  }
}

.no-padding {
  padding: 0;
}

.adjust-btn {
  margin: 15px 17px 0;
  float: right;
}

.machine-report-edit {
  display: inline-block;
  float: left;
  padding: 15px 0 15px;
  .date-lable{
    padding-top: 5px;
  }
  .right-space {
    margin-right: 15px;
  }
}

.left-space {
  margin-left: 15px;
}

#foo {
  //min-height: 335px;
  min-height: 480px;
  height: auto;
  h2 {
    font-weight: 700;
    color: var(--header-title-color);
    float: left;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.no-line-height {
  line-height: 0;
}

.full-width {
  width: 100%;
}

.month-year {
  width: 100%;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  color: #636363;
  box-shadow: none;
  border: 1px solid #cccccc;
}

.style-cal {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 21px;
  bottom: 10px;
  color: black;
}

.filters-padding {
  padding: 15px 0 15px;
}

.no-left-padding {
  padding-left: 0;
}

.no-margin{
  margin: 0;
}

.top-bottom-margin {
  margin: 10px 0 10px;
  float: left;
}

.margin-refresh {
  margin-bottom: 10px;
}

.hamburger {
  background: #4d5a77;
  float: right;
  cursor: pointer;
  padding: 6px 10px;
  margin-left: 10px;
  outline: none;
  i {
    margin: 0;
    font-size: 1em;
    color: #fff;
    font-weight: bold;
  }
}

.overflow-visible {
    overflow-y: visible !important;
}

#filters-section {
  animation-duration: 0.3s;
}

.visible-filters {
  display: block;
}

.hide-filters {
  display: none;
}

.machine-preview {
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0;
  }
}

.min-padding{
  padding: 6px;
}

.min-margin-left {
  margin-left: 5px;
}

.min-top{
  top: 5px;
}

.no-data {
  text-align: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ddd;
}

//Dropdown SCSS
.c-btn{
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
  .clear-all {
    display: none;
  }
}

.read-only {
  pointer-events: none;
  .c-btn.disabled {
    background: #fff;
  }
}


.selected-list{
  .c-list{
    .c-token{
      padding: 0 23px 0 5px !important;
      margin-top: 0 !important;
      background: var(--updated-primary-color) !important;
      .c-label{
        color: #fff;
      }
      .c-remove {
        svg {
          fill: #fff;
        }
      }

    }
  }
  .c-angle-down, .c-angle-up{
    svg {
    }
  }}

.dropdown-list{
  ul{
    li:hover{
      background: #f5f5f5;
    }
  }
}

.arrow-up, .arrow-down {
  border-bottom: 15px solid #fff;
}

.arrow-2{
  border-bottom: 15px solid #ccc;
}

.list-area{
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 1px 5px #959595;
  .filter-select-all {
    padding: 10px 10px 5px;
    text-align: center;
  }
  .list-message {
    text-align: center;
    margin: 0;
    padding: 15px 0;
    font-size: inherit;
    font-weight: 400;
  }
}

.select-all{
  border-bottom: 1px solid #ccc;
}

.list-filter{
  border-bottom: 1px solid #ccc;
  .c-search{
    svg {
      fill: #888;
    }
  }
  .c-clear {
    cursor: pointer;
    svg {
      fill: #888;
    }
  }
}

.pure-checkbox {
  input[type="checkbox"]:focus + label:before, input[type="checkbox"]:hover + label:before{
    border-color: #0079FE;
    background-color: #f2f2f2;
  }
  input[type="checkbox"] + label{
  }
  input[type="checkbox"] + label:before{
    //color: #0079FE;
    color: var(--drop-down-check-box-color);
    //border: 2px solid #0079FE;
    border: 2px solid var(--drop-down-check-box-color);
  }
  input[type="checkbox"] + label:after{
    background-color: var(--drop-down-check-box-color);
  }
  input[type="checkbox"]:disabled + label:before{
    border-color: #cccccc;
  }
  input[type="checkbox"]:disabled:checked + label:before{
    background-color: #cccccc;
  }
  input[type="checkbox"] + label:after{
    border-color: #ffffff;
  }
  input[type="radio"]:checked + label:before{
    background-color: white;
  }
  input[type="checkbox"]:checked + label:before{
    //background: #0079FE;
    background: var(--drop-down-check-box-color);
  }
}

.selected-item{
  background: #e9f4ff;
}

.machine-breakdown {
  .break-down-header {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .close{
    display: none !important;
  }
}

.crop-list .dropdown-menu, .filters-drop-style .dropdown-menu {
  z-index: 1 !important;
}

.machine-spare-grid, .product-part-container, #userRoleDropDown {
  .dropdown-list {
    position: relative;
    z-index: 5;
  }
  .no-data-found virtual-scroll {
    height: auto !important;
  }
}

//Drill down User Confirm Modal PopUp
.confirmUserActiveContainer.user-confirm-container {
  .modal-content {
    padding: 20px;
    button.close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;
      margin: 0;
    }
    .modal-footer {
      display: none;
    }
  }
}

.confirmUserActiveContainer.machine-spare-details-preview {
  .modal-dialog {
    max-width: 770px;
    .confirmButtonsContainer {
      height: 350px;
      overflow-y: hidden;
    }
    .modal-content {
      padding: 0;
      height: 12px;
    }
  }
}

#productsList {
  .dropdown-menu {
    max-height: 50px !important;
    position: relative;
    @media only screen and (max-width: 767px) {
      max-width: 370px;
      padding: 0 5px;
    }
  }
  @media only screen and (max-width: 767px) {
    .dropdown-item {
      padding: 10px 0;
    }
  }
}
.product-list-wrapper {
  #productsList {
    position: absolute;
    width: 90%;
  }
}
.nsm-overlay-open {
  display: flex;
  align-items: center;
  .nsm-content {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .selected-products {
    margin: 30px 0 10px !important;
  }
  .nsm-dialog-btn-close {
    display: none;
  }
}

.remote-section {
  /* Just use .gm-style-iw */
  .gm-style-iw {
    min-height: 380px;
    max-height: 380px !important;
  }
  .gm-style .gm-style-iw-d {
    box-sizing: border-box;
    overflow: auto;
    min-height: 380px;
  }
}

.new-line {
  word-wrap: break-word;
}

.farmer-infow-window {
  padding: 0;
}

.farmer-panel {
  background: #989494;
  display: flex;
  padding-top: 7px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

div.infowindow {
  max-height:250px;
  overflow-y:auto;
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}

.farmer-picture {
  width: 70px;
  height: 70px;
}

.ndvi-bands {
  width: 15px;
  height: 14px;
  float: left;
  margin-right: 5px;
}

.goog-te-gadget-simple {
  width: 100px;
}

.goog-te-gadget img {
  display: none;
}

#google_translate_element {
  position: absolute;
  z-index: 1;
  top: 100px;
  left: 45px;
}

.goog-te-gadget-simple {
  width: 125px;
  border: 1px solid gray;
  border-radius: 5px;
}

.goog-te-menu-value {
  span {
    &:last-child {
      position: absolute;
      right: 8px;
    }
    &:nth-child(2) {
      display: none;
    }
  }
}

.goog-te-banner-frame {
  height: 0 !important;
}

#legend, #soilLegend {
  padding: 0 10px;
  left: 30% !important;
  margin-bottom: 10px;
  background: #fff;
  justify-content: center;
  align-items: center;
  > div {
    padding: 5px 0;
  }
  > span{
    width:15px;
    height:13px;
  }
  @media only screen and (max-width: 767px) {
    flex-flow: row wrap;
    width: 160px;
    left: 0 !important;
  }
}

#land-title {
  margin: 10px 0;
  color: #4d5a77;
  font-size: 13px;
}

.farmer-main {
  //Land Modal PopUp SCSS
  .addLandModal {
    .modal-header {
      display: block;
    }
  }
}

.user-container {
  .confirmUserActiveContainer.export-reports-wrapper {
    .modal-header {
      display: block;
    }
  }
}

//Unimart Trip Details SCSS
.user-container {
  .confirmUserActiveContainer.trip-details-wrapper {
    .close {
      display: none;
    }
    .modal-header {
      display: block;
    }
  }
}

.confirmationModalPopUp {
  .machine-image-section {
    img {
      object-fit: cover;
    }
  }
}

//farmer GUID gloab btn
.global-btn {
  background: #4E6539 !important;
  border-radius: 5px !important;
}

#farmer-infow-window {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 0;
    border-radius: 0;
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
    width: 50%;
    border-right: 1px solid #fff;
  }
  .nav.nav-tabs {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 1px solid transparent;
    background-color: #737e95;
  }
  .card-header {
    padding: 2px;
    margin-bottom: 0;
    background-color: #f1f1f1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .nav.nav-tabs > li:first-of-type a {
    border-top-left-radius: 0;
  }
  .nav.nav-tabs > li > a:hover {
    border: none;
    background-color: #000000;
  }
  .nav.nav-tabs a:hover {
    color: #ffffff;
  }
  .tab-content .tab-pane p {
    color: #2f2f2f;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background: linear-gradient(90deg, rgba(47, 97, 33, 0.82) 0%, rgb(53, 32, 0) 1%, rgba(44, 255, 21, 0.51) 97%);
  }
  .tab-content {
    padding: 0;
    color: #4d5a77;
  }
  .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

//Export To Email SCSS
.user-container {
  .export-reports-wrapper {
    .modal-content {
      padding: 20px;
      .modal-header {
        .close {
          top: -5px;
        }
      }
      .modal-body {
        padding: 20px 0;
        .export-to-email {
          .confirmButtons {
            .error-message {
              .help-block {
                color: red;
              }
            }
            .btn-section {
              padding: 20px 0 0 0;
              .noButton, .yesButton {
                width: 50%;
              }
              .yesConfirm, .noConfirm {
                width: 50%;
                height: 35px;
                border: none;
                font-size: 13px;
                border-radius: 3px;
                outline: 0;
                color: #fff;
                cursor: pointer;
              }
              .yesConfirm {
                background-color: #a22e2a;
              }
              .disable-submit {
                opacity: 0.5;
                cursor: not-allowed !important;
              }
              .noConfirm {
                float: right;
                background-color: grey!important;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}

/*Selected Images*/
.selected-image-modal-container {
  .confirmationModalPopUp.image-wrapper {
    overflow-y: scroll;
  }
}

.wrap {
  margin-top: 10px;
  display: flex;
  left: 10px !important;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .switch-block {
    width: 95px;
    padding: 3px 6px;
    margin-left: 10px;
    color: black;
    align-items: center;
    border: 1px solid #d4d4d4;
    font-size: 11px;
    z-index: 1;
    display: flex;
    background: #fff;
    span {
      margin-right: 5px;
    }
  }
  /* Normal Track */
  input[type="checkbox"].ios-switch + div {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 999px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: .4s;
    -webkit-transition-property: background-color, box-shadow;
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  /* Checked Track (Blue) */
  input[type="checkbox"].ios-switch:checked + div {
    width: 40px;
    background-position: 0 0;
    background-color: #3b89ec;
    border: 1px solid #0e62cd;
    box-shadow: inset 0 0 0 10px rgba(59, 137, 259, 1);
  }
  /* Tiny Track */
  input[type="checkbox"].tinyswitch.ios-switch + div {
    width: 34px;
    height: 18px;
    cursor: pointer;
  }
  /* Normal Knob */
  input[type="checkbox"].ios-switch + div > div {
    float: left;
    width: 18px;
    height: 18px;
    border-radius: inherit;
    background: #ffffff;
    -webkit-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
    -webkit-transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color, box-shadow;
    -moz-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
    -moz-transition-duration: 0.4s;
    -moz-transition-property: transform, background-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.4);
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
  }
  /* Checked Knob (Blue Style) */
  input[type="checkbox"].ios-switch:checked + div > div {
    -webkit-transform: translate3d(20px, 0, 0);
    -moz-transform: translate3d(20px, 0, 0);
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(8, 80, 172, 1);
  }
  /* Tiny Knob */
  input[type="checkbox"].tinyswitch.ios-switch + div > div {
    width: 16px;
    height: 16px;
    margin-top: 0;
  }
  label {
    display: inline-block;
    margin-bottom: 0;
  }
  .reset-map {
    .fa-inverse {
      color: #141414;
      background: #fff;
      margin-top: 1px;
      padding: 1px;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.map-span-toggle {
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  z-index: 1111111;
  position: absolute;
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  display: flex;
}

.not-found-users {
  position: absolute;
  background: #FFFFFF;
  top: 30px;
  border-radius: 0;
  height: 30px;
  width: 30px;
  overflow: hidden;
  transition: width 0.5s, height 0.5s;
  line-height: 1.5rem;
  font-weight: 700;
  color: #374767;
  text-align: center;
  .container {
    display: none;
    position: relative;
    padding-left: 25px;
    text-align: left;
    font: 400 11px Roboto, Arial, sans-serif;
    text-decoration: none;
    color: black;
    line-height: 2;
    font-size: 11px;
    width: 114px;
    margin: 0;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  /* Hide the browser's default radio button */
  .rs-filter-tab input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}

.not-found-users:hover {
  width: 223px;
  height: 115px;
  border-radius: 0;
  padding: 10px 7px;
  .container {
    display: inline-block;
  }
  .rs-search-container {
    width: 100%;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid transparent;
    outline: none;
    float: left;
    font-size: 11px;
    padding: 4px 3px;
    .rs-search-input {
      width: calc(100% - 30px);
      border-radius: 2px;
      outline: none;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      float: left;
      font-size: 11px;
      padding: 2px 5px;
      border-left: 1px solid gray;
      border-bottom: 1px solid gray;
      border-top: 1px solid gray;
    }
    .rs-input-icon {
      width: 30px;
      float: left;
      padding: 3px;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      border-bottom-right-radius: 3px;
      line-height: 1.25;
      border-top-right-radius: 3px;
      color: #464a4c;
      border-left: none;
      text-align: center;
      background-color: #eceeef;
      display: inline-block;
      border: 1px solid rgba(128, 128, 128, 0.7);
    }
  }
  .rs-farmer-filter-container {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    float: left;
  }
}

.not-found-users.rs-tab-filter-wrapper {
  &:hover {
    height: 160px;
  }
}

.rs-data-tab {
  z-index: 9999999999999;
  display: inline-block;
  width: auto;
  margin: 0;
  position: absolute;
  min-width: 100%;
  flex: 0 0 100%;
  overflow-y: auto;
  background-color: #fff;
  min-height: 0;
  max-height: 60vh;
  bottom: 5px;
  max-width: 100%;
}
.rs-user-container {
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
}

.geography-form {
  .label-style {
    color: #000000;
    font-weight: bold;
    opacity: 0.85;
  }
}

.confirmUserActiveContainer.voice-container {
  .modal-dialog {
    max-width: 650px;
    .modal-content {
      background-color: #ECF2F8;
    }
  }
  .confirmButtonsContainer.confirmationModalPopUp.farmer-voice-process-main-wrapper {
    height: 430px;
    padding: 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .selected-image-modal-container {
    .modal-dialog {
      margin: 10px;
    }
    .confirmationModalPopUp.image-wrapper {
      height: 450px !important;
      padding: 20px 0;
      overflow-y: scroll;
    }
  }
  #google_translate_element {
    top: 170px;
  }
  .confirmUserActiveContainer.voice-container {
    .confirmButtonsContainer.confirmationModalPopUp.farmer-voice-process-main-wrapper {
      height: 390px;
    }
  }

  .confirmUserActiveContainer {
    .confirmButtonsContainer.confirmationModalPopUp.video-scanner-wrapper {
      height: 70vh;
      video {
        height: 100%;
      }
    }
  }
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 99999999999 !important;
}

/* The checkbox container */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #d6d1d1;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #4CAF50;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.editable {
  width: 100%;
  outline: none;
  height: 30px;
  border: 1px solid #b9b7b7;
  border-radius: 3px;
  padding: 2px;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.farmerGUID {
  .gm-style-iw {
    min-height: 300px;
    max-height: 300px;
  }
  .gm-style-iw-d {
    max-height: 289px !important;
  }
}
.no-margin-center {
  margin: 0 auto;
}

.unimart-dropdown-list, .booking-parent-container {
  .dropdown-list {
    z-index: 1;
  }
}
.unimart-user-grid {
  .border-row.fixed-min-width {
    &:nth-child(6), &:nth-child(7) {
      min-width: 90px;
      max-width: 90px;
    }
  }
}
.unimart-user-grid.unimart-user-table {
  .border-row {
    &:nth-child(2), &:nth-child(6) {
      width: 160px !important;
    }
    &:nth-child(5) {
      width: 120px !important;
    }
  }
}
.unimart-user-table.retail-user {
  .border-row {
    &:nth-child(5) {
      width: 160px !important;
    }
  }
}
#outletDropdown {
  .cuppa-dropdown {
    .c-btn {
      font-size: 13px;
    }
    .dropdown-list {
      position: absolute;
      padding-top: 14px;
      width: 100%;
      z-index: 1 !important;
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }
}

.disable-submit {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.main-menu-list {
  ul.child-submenu {
    &:hover {
      background-color: transparent !important;
    }
    ba-menu-item {
      li {
        a {
          padding-left: 30px;
        }
      }
    }
  }
}
.routLink:hover {
  text-decoration:underline !important;
  color: #0e62cd !important;
}
.button-background {
  background-color: $button-color;
}

/**
 Date Range Picker SCSS
 */
.mydrp {
  .selector {
    z-index: 1 !important;
  }
  .titleareatxt {
    height: auto !important;
    line-height: 20px !important;
    font-size: 14px !important;
    border-bottom: 1px solid #EEE;
    font-weight: 600;
    padding: 10px;
  }
}

.from-to-date {
  .mydp {
    .selector {
      z-index: 1
    }
  }
}

.confirmUserActiveContainer.machineConfirmationWrapper, .confirmUserActiveContainer.confirmOutletDeleteContainer {
  button.close {
    position: absolute;
    right: 0;
    padding-right: 10px;
    margin-top: -18px;
    cursor: pointer;
  }

  .modal-body {
    padding: 0 0 20px;
    top: 20px;
  }

  .modal-footer {
    display: none;
  }

  .modal-content {
    padding: 20px;
  }
}

/**
 Announcement Modal SCSS
 */
.announcement-modal-container {
  .modal-dialog {
    max-width: 600px;

    .modal-header {
      padding: 0;

      button.close {
        display: none;
      }
      modal-header {
        background: #4d5a77;
        margin: 5px;
        border-radius: 2px;
        width: 100%;
        color: #fff;
        padding: 10px;
      }
    }

    .modal-body {
      padding: 0;
      modal-content {
        float: left;
        width: 100%;
        padding: 20px;
        .confirmButtonsContainer {
          height: auto;
          padding: 0;
        }
      }
    }

    .modal-footer {
      display: none;
    }
  }
}

.confirmUserActiveContainer.preview-section-container {
  .modal-header, .modal-footer {
    display: none;
  }
}

.crop-wrapper, .unimart-dropdown-list {
  .dropdown-list {
    .filter-select-all {
      .pure-checkbox {
        display: none;
      }
    }
  }
}

#outletDropdown {
  .selected-list {
    .c-list {
      width: calc(100% - 40px);
    }
  }
}

/* State Filter SCSS on Machine on Map Page */
.machine-state-filter, .dropdown-menu-container {
  .cuppa-dropdown {
    .dropdown-list {
      z-index: 1;
    }
  }
}

.campaign-booking-container {
  .steps-indicator {
    display: none;
  }
}


#createFarmer.confirmUserActiveContainer {
  .modal-content {
    .modal-header, .modal-footer {
      display: none;
    }
    .modal-body {
      .confirmButtonsContainer {
        height: auto;
        padding: 0;
      }
    }
  }
  .modal-dialog {
    max-width: 700px !important;
    .modal-content {
      .modal-body {
        padding: 0;
      }
    }
  }
}
.confirmMarkAsValid.confirmUserActiveContainer {
  .modal-content {
    padding: 15px;
    .modal-header {
      display: flex;
      flex-direction: row-reverse;
      button.close {
        position: relative;
        right: 0;
        padding-right: 0;
        margin-top: 0;
      }
    }
    .modal-body {
      top: initial;
    }
    .modal-footer {
      display: none;
    }
  }
}
.filter-wrapper-container {
  .dropdown-list {
    z-index: 1 !important;
  }
}
.owl-dialog-container {
  overflow: hidden;
}

#products {
  .dropdown-menu {
    position: relative;
  }
}

input.form-control.date-time-picker::placeholder,
textarea.form-control.other-section::placeholder {
  color: #464a4c
}
.custom-class {
  .selected-list {
    .c-btn.disabled {
      background: #eceeef;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #464a4c;
    }
  }
}
.pagination-control {
  ul {
    padding-left: 0;
    .disabled {
      padding: 0.1875rem 0.625rem;
      margin-left: 0.9px;
      color: #cacaca;
      cursor: not-allowed;
    }
    li {
      border: 1px solid #d6d6d6;
      margin-left: -2px;
      height: 35px;
      text-align: center;
      min-width: 35px;
      line-height: 27px;
    }
    .current {
      background: #a22e2a !important;
      color: #fff;
    }
  }
}
.slimScrollBar {
  opacity: 0 !important;
}
.pure-checkbox {
  display: flex;
  align-items: center;
}
.lazyContainer {
  .pure-checkbox {
    color: #333;
  }
}
.pure-checkbox.disable-list {
  pointer-events: none;
  opacity: 0.8;
  background: #eceeef;
  color: #666666;
}
.confirm-modal-container.confirmUserActiveContainer {
  .modal-content {
    .modal-header {
      display: block;
      text-align: center;
      .close {
        display: none;
      }
      .test {
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-bottom: 10px;
        .headerText {
          font-size: 18px;
          color: #000;
        }
      }
    }
    .modal-body {
      .confirmButtons {
        text-align: center;
        .noButton {
          .noConfirm {
            background: transparent;
            border: 1px solid #A22E1D;
            outline: none;
            padding: 5px 35px;
            border-radius: 5px;
            color: #a22e2a;
            cursor: pointer;
            margin-bottom: 10px;
          }
        }
      }
    }
    .modal-footer {
      display: none;
    }
  }
}

.confirmMarkAsValid.confirmUserActiveContainer .modal-content .modal-header button.close {
  position: relative;
  right: 0;
  display: none;
  padding-right: 0;
  margin-top: 0;
}
.confirmMarkAsValid.confirmUserActiveContainer .modal-content .modal-header {
  display: flex;
  margin: auto;
}
.switch-small[_ngcontent-c9] {
  width: 35px;
  height: 21px;
  border-radius: 20px;
  top: 5px;
}

.confirmUserLicenseActiveContainer .modal-body{
  max-height:500px;
  overflow:scroll;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

//.confirmUserLicenseActiveContainer {
//  @include confirmDialogueActiveInActive();
//}
//
//
//.confirmUserLicenseActiveContainer {
//  @include confirmDialogueActiveInActive();
//}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserLicenseActiveContainer .modal-body{
  max-height:500px;
  overflow:scroll;
}



.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

//.confirmUserLicenseActiveContainer {
//  @include confirmDialogueActiveInActive();
//}
//
//
//.confirmUserLicenseActiveContainer {
//  @include confirmDialogueActiveInActive();
//}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}


.confirmButtonsContainer.confirmationModalPopUp {
  height: 300px;
  padding: 20px 0;
}
.floating-preview-buttons {
  float: left;
  width: 30%;
  margin: 0 35%;
  position: absolute;
  bottom: -60px;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  .cross {
    position: relative;
    border: 3px solid #fff;
    height: 30px;
    width: 30px;
    right: -12px;
    top: -11px;
    border-radius: 25px;
    background: #fff;
    .fa {
      position: absolute;
      right: 3px;
      font-size: 22px;
      cursor: pointer;
      color: #a22e2a;
      font-style: normal;
    }
  }
}

.confirmUserLicenseActiveContainer {
  .modal-footer{
    display:none;
  }
  .fade{
    opacity: 1 !important;
  }
  .confirmButtonsContainer{
    width:100%;
    height:50px;
    padding: 20px 0 0 0;
    .remark-title {
      float:left;
      padding: 0 16px
    }
    .remark-box {
      resize: none;
      height: 100px !important;
      width: 94%;
      margin: 1px 1% 24px 16px;
    }
  }
  .confirmBookingsContainer{
    width:100%;
    height:50px;
  }
  .modal-dialog {
    position: relative;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;

  }
  .modal-content {
    padding: 20px;
  }
  button.close {
    position: absolute;
    right: 0;
    padding-right: 10px;
    margin-top: -18px;
    cursor: pointer;
  }
  .modal-body {
    padding: 0 0 20px;
    top: 20px;
  }
}

.modal-header-updated {
  top: 0;
  right: 0;
  left: 0;
  padding: 0;
  background: rgba(129, 129, 129, 0.71);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.modal-title-updated {
  color: #fff;
  line-height: 15px;
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.close-btn-updated {
  background: #fff;
  color: red;
  font-size: 20px;
  padding: 6px;
  cursor: pointer;
}

.modal-content-updated {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-dialog-updated{
  margin: 0 auto;
  position: relative;
  width: calc(100% - 10%);
  max-width: 100%;
  height: calc(100% - 40px);
  top: 20px;
}

.modal-body-updated {
  position: absolute;
  width: 100%;
  height: 90%;
  overflow: auto;
  flex: 1 1 auto;
  padding: 15px;
}


.updated-menu-container {
  .cuppa-dropdown {
    .dropdown-list {
      z-index: 1;
      left: initial !important;
      top: initial !important;
      position: absolute !important;
    }
    .dropdown-list ul {
      position: relative !important;
    }
  }
}

.cuppa-dropdown {
  .dropdown-list {
    left: initial !important;
    top: initial !important;
    position: absolute !important;
  }
  .dropdown-list ul {
    position: relative !important;
  }
}

.updated-form-fields {
  .Updated-primary-button {
    width: 160px;
    min-height: 42px;
    background: var(--updated-backgroud-linear-gradient) !important;
    color: #FFFFFF;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    box-shadow: none;
    &:focus {
      outline: none;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.3) !important;
      color: #FFFFFF;
      cursor: not-allowed;
    }
  }

  .Updated-secondry-button {
    width: 160px;
    min-height: 42px;
    background: #FFFFFF;
    border: 1px solid var(--updated-primary-color);
    border-radius: 8px !important;
    color: var(--updated-primary-color);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    box-shadow: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.3) ;
      background: rgba(0, 0, 0, 0.3) ;
      cursor: not-allowed;
    }
  }

  .Updated-verify-button {
    color: rgb(255, 255, 255);
    background-color: rgb(44, 138, 0);
    min-height: 45px;
    font-size: 16px;
    font-weight: 600;
  }

  .Updated-verify-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: rgb(44, 138, 0);
    color: #fff;
  }
}

.cdk-overlay-container {
  z-index: 1001;
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.cdk-global-scrollblock {
  position:  initial ;
}
.overlay-container{
  .toast-top-right {
    left: 50%;
    top: 92%;
    right: unset;
    transform: translate(-50%, -50%);
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: unset;
}

.updated-primary-large-btn {
  width: 230px;
  height: 36px;
  background: var(--updated-backgroud-linear-gradient) !important ;
  color: #FFFFFF;
  border-radius: 8px;
  font-family: var(--updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.3) !important;
    cursor: not-allowed;
  }
  &:hover {
    color: #FFFFFF;
  }
}


.updated-secondry-large-btn {
  width: 230px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid var(--updated-primary-color);
  border-radius: 8px !important;
  color: var(--updated-primary-color);
  font-family: var(--updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.3) ;
    background: rgba(0, 0, 0, 0.3) ;
    color: #FFFFFF;
    cursor: not-allowed
  }
  // &:hover {
  //   color: var(--updated-primary-color);
  // }
}


.updated-primary-medium-btn {
  width: 160px;
  height: 36px;
  background: var(--updated-backgroud-linear-gradient) !important ;
  color: #FFFFFF;
  border-radius: 8px;
  font-family: var(--updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.3) !important;
    cursor: not-allowed;
  }
  &:hover {
    color: #FFFFFF;
  }
}


.updated-secondry-medium-btn {
  width: 160px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid var(--updated-primary-color);
  border-radius: 8px !important;
  color: var(--updated-primary-color);
  font-family: var(--updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.3) ;
    background: rgba(0, 0, 0, 0.3) ;
    color: #FFFFFF;
    cursor: not-allowed;
  }
  &:hover {
    color: var(--updated-primary-color);
  }
}

.updated-primary-small-btn {
  width: 50px;
  height: 36px;
  background: var(--updated-backgroud-linear-gradient) !important ;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #FFFFFF;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.3) !important;
    cursor: not-allowed;
  }&:hover {
    color: #FFFFFF;
  }
}

.updated-secondry-small-btn {
  width: 50px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid var(--updated-primary-color);
  border-radius: 8px !important;
  color: var(--updated-primary-color);
  font-family: var(-updated-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.3) ;
    background: rgba(0, 0, 0, 0.3) ;
    color: #FFFFFF;
    cursor: not-allowed;
  }
  &:hover {
    color: var(--updated-primary-color);
  }
}


.cdk-overlay-container {
  z-index: 1001;
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:var(--updated-primary-color); 
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:var(--updated-primary-color);
  background-color:var(--updated-primary-color) ;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:var(--updated-primary-color);
}

// for ngx-img-zoom library 
.img-zoom-container {
  width: 100%;
  height: 100%;

  .img-zoom-lens {
    width: 150px !important;
    height: 150px !important;
    border: 1px solid red !important;
  }
}