@import 'conf/conf.scss';

$left-space: $sidebar-width;

@include scrollbars(.7em, #a22e2a, rgba(0, 0, 0, 0));

html {
  position: relative;
  min-width: 320px;
}

html, body {
  min-height: 100%;
  height: 100%;
  min-width: $resMin;
  background-color: $body-bg;
}

main {
  min-height: 100%;
  position: relative;
  color: #666666;

  @include body-bg();

  .additional-bg {
    display: none;
    @include additional-bg();
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow: hidden;
    height: 100%;
  }
  body {
    overflow: auto;
    height: 100%;
  }
}

a {
  transition: color 0.5s ease;
  outline: 0 !important;
}

.body-bg {
  display: none;
}

.al-header {
  display: block;
  height: 49px;
  margin: 0;
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
  color: #444444;
}

.al-main {
  margin-left: $left-space;
  padding: 66px 0 34px 0;
  min-height: 620px;
  position: relative;
  background-color: #ffffff;
}

.al-footer {
  height: 34px;
  padding: 0 18px 0 ($left-space + 20px);
  width: 100%;
  line-height: 35px;
  // position: absolute;
  display: block;
  // bottom: 0;
  font-size: 13px;
  background-color: $body-bg;
  color: $default-text;
  transition: padding-left 0.5s ease;
}

.al-footer-main {
  float: left;
  margin-left: 15px;
}

.al-copy {
  float: left;
  color: #374767;
  a{
    color: #374767;
  }
}

.al-footer-right {
  float: right;
  margin-right: 12px;
  i {
    margin: 0 4px;
    color: $danger;
    font-size: 12px;
  }
  a {
    margin-left: 4px;
    color: $default-text;
    &:hover {
      color: $danger;
    }
  }
}

.al-share {
  margin: -6px 0 0 12px;
  padding: 0;
  list-style: none;
  float: left;
  li {
    list-style: none;
    float: left;
    margin-left: 16px;
    i {
      cursor: pointer;
      transition: all 0.1s ease;
      color: white;
      padding: 6px;
      box-sizing: content-box;
      font-size: 16px;
      &:hover {
        transform: scale(1.2);
      }
    }
    i.fa-facebook-square {
      color: $facebook-color;
    }
    i.fa-twitter-square {
      color: $twitter-color;
    }
    i.fa-google-plus-square {
      color: $google-color;
    }
  }
}

.al-content {
  position: fixed;
  width: calc(100% - 180px);
  background-color: #eeeeee;
  border: 1px solid gainsboro;
  padding: 8px 32px 8px 12.8px;
  z-index:9
  //padding: 0px 2px 8px 2px;
}

@media screen and (max-width: $resXS) {
  .al-content {
    padding: 8px 20px;
    z-index: 3;
  }
}

.vis-hidden {
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.icon-up, .icon-down {
  width: 5px;
  height: 13px;
  display: block;
}

.icon-up {
  //@include bg-nr('arrow-green-up.svg');
  background: url('/assets/img/arrow-green-up.svg') no-repeat 0 0;
}

.icon-down {
  background: url('/assets/img/arrow-red-down.svg') no-repeat 0 0;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  user-select: none;
}

.align-right {
  text-align: right
}

.amcharts-chart-div > a {
  font-size: 6px !important;
}

.content-panel {
  padding-left: 22px;
  padding-top: 26px;
}

@media (max-width: 590px) {
  .al-footer-right {
    float: none;
    margin-bottom: 19px;
    margin-right: 0;
  }
  .al-footer {
    height: 76px;
    text-align: center;
  }
  .al-main {
    padding-bottom: 76px;
  }
  .al-footer-main {
    float: none;
    display: inline-block;
  }
}

.full-invisible {
  visibility: hidden !important;
  * {
    visibility: hidden !important;
  }
}

.irs-grid-text {
  color: $default-text;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
