@import "../../theme/sass/_auth";
@import "../back-office/salary-report/salary-report.component.scss";

.dash-board-container {
  margin: 100px 2.5% 15px 2.5%;
  padding: 15px;
  overflow-y: auto;
  min-width: 95%;
  border-radius: $border-radius;
  .month-chart {
    min-width: 100%;
    height: 360px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto 20px;
  }
  #outletDropdown {
    width: 270px;
  }
  .business-wrapper {
    padding-right: 15px;
    width: 190px;
    select {
      font-size: 13px;
    }
  }
  .disable-btn {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .info-message {
    width: 100%;
    margin: 5px 0 0;
    padding: 0;
    color: #4d5a77;
    font-weight: bold;
    font-size: 13px;
  }
}
.inline-block {
  display: inline-block;
}
.no-data-dashboard {
  position: relative;
  display: flex;
  justify-content: center;
}

.no-data-charts {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #4d5a77;
  text-align: center;
  text-align: -moz-center;
  -webkit-transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  transform: translate(0%, 50%);
  right: 0;
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .dash-board-container {
    .dashboard-content {
      .without-padding {
        padding:0
      }
    }
  }
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    filter: contrast(50%);
  }
}
.badge-size {
  font-size: 2.5rem;
  width: 30px;
}
.bg-white {
  background: #fff;
}
.no-border-back-shadow {
  border: none;
  background-repeat: repeat-x;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.branchwise-outlet {
  width: calc(13 * 120px) !important;
}
.min-height-400 {
  min-height: 400px !important;
}
.outlet-title {
  color: #fff;
  background-color: var(--updated-primary-color);
  border-radius: var(--global-radious);
  font-weight: bold;
}

.gradient-blackberry {
  background-color: #fff;
  color: #4d5a77;
}

.chart-type {
  cursor: pointer;
}

.show-on-med-device {
  button {
    padding: 8px 10px;
    background: #4d5a76;
    border: none;
    box-shadow: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      color: #fff;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 1299px) {
  .hide-on-med-device {
    display: none;
  }
  .show-on-med-device {
    display: block;
  }
}

@media only screen and (min-width: 1300px) {
  .hide-on-med-device {
    display: block;
  }
  .show-on-med-device {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .dash-board-container {
    .business-wrapper {
      margin: 10px 0;
      padding-right: 0;
      width: 100%;
    }
    #outletDropdown {
      width: 100%;
      margin: 5px 0 15px 0;
    }
    .show-on-med-device {
      padding: 0;
      button {
        float: right;
      }
    }
  }
  .no-data-dashboard.mb-2.pb-2 .col-md-3 {
    padding: 0;
  }
}