.dropdown-item {
  line-height: 1;
}
.dropdown-item:active {
  background: transparent;
}

.dropdown-menu {
  font-size: inherit;
}


