@import 'sass/conf/conf.scss';
@import 'sass/bootstrap-overrides/overrides.scss';
@import 'animate.css/animate.css';
@import 'sass/typography';
@import 'sass/icons';
@import 'sass/layout';
@import 'sass/table';
@import 'sass/form';
@import 'sass/modal';
@import 'sass/preloader';
@import 'sass/ionicons';

