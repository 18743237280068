.geography-creation-container {
  display: block;
  padding: 0;
  .geo-type-title {
    font-weight: 700;
    color: #4d5a77;
    float: left;
    padding: 0;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .radio-user-role {
    .radio-margin {
      margin-right: 20px;
      display: inline-flex;
      align-items: center;
      .radio-label {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
}
.data{
  width: 100%;
  margin-left: 0px ;
}
.size {
    &:nth-child(1) {
      width: 50%;
    }
}
