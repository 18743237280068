@import "../../../theme/sass/auth";
@import "../../../theme/sass/mixins.scss";
 

.bottom-padding {
  padding-bottom: 30px;
  .user-role-label {
    margin: 5px 26px 0 5px;
  }
  .user-role {
    .btn:disabled {
      cursor: not-allowed;
      opacity: .9;
    }
  }
  .select-note {
    font-size: 12px;
    position: relative;
    top: 10px;
    left: 2px;
  }
}

.assign-input-button {
  line-height: 2.8;
  padding: 30px;
  .btn-style{
    float: left !important;
  }
}

.status-control {
  position: relative;
  .checkboxes label {
    display: block;
    float: left;
    padding-right: 10px;
    white-space: nowrap;
    position: relative;
    top: 6px;
  }
  .checkboxes input {
    vertical-align: middle;
  }
  .checkboxes label span {
    vertical-align: middle;
    padding-left: 5px;
  }
}

.operator-machine-container {
  margin: 100px 2.5% 30px 2.5%;
  padding: 15px;
  background: white;
  min-width: 95%;
  min-height: 65vh;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  .firtst-chiled{
    display: inline-block;
  }
  .not-assigned {
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
    background: #f1f3f6;
  }
  .operator-machine {
    min-height: 30vh;
    tr{
      border: 1px solid #f1f3f6;
    }
    th {
      border: 1px solid #f1f3f6;
      font-weight: bold;
      text-align: left;
      height: 40px;
      color: #0059b3;
      line-height: 18px;
    }
    .un-assign-action {
      text-align: center;
    }

    td{
      border: 1px solid #ddd;
      font-size: 12px;
      color: #0059b3;
      height: 40px;
      text-align: left;
      padding: 3px 5px;
      max-width: 100px;
      word-wrap: break-word;
      .un-assign-btn {
        min-width: 90px;
        line-height: 30px;
        float:none;
      }
    }
  }
}


.common-container {
  margin: 100px 2.5% 50px 2.5%;
  padding: 15px;
  background: white;
  min-width: 95%;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  .profile-images {
    font-size: 15px;
    color: #777777;
  }
  .input-button {
    text-align: center;
    margin: 1.9% 0 2% 0;
  }
  .form-control {
    border-radius: $border-radius;
  }
  .form-control[disabled] {
    color: grey;
    border-color: lightgrey;
  }
  .disable-submit {
    opacity: 0.8;
    cursor: not-allowed !important;
    &:hover {
      opacity: 0.8;
      cursor: not-allowed !important;
    }
  }
  .profile-content {
    margin-top: 2%;
    margin-left: 2.5%;
    margin-right: 0;
    .profileForm {
      .personal-information-section {
        width: 100%;
        float: left;
        padding: 15px;
        border-left: 1px solid rgba(0, 0, 0, 0.15);
        margin: 15px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        .bottom-padding {
          padding-bottom: 20px;
        }
      }
      .bank-information-section {
        width: 100%;
        float: left;
        .bank-details {
          width: 100%;
          float: left;
          padding: 15px;
          border-left: 1px solid rgba(0, 0, 0, 0.15);
          margin: 15px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
        }
      }
    }
    .info-title {
      font-weight: 700;
      color: #374767;
      float: left;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 15px;
      text-transform: uppercase;
      opacity: 0.9;
    }
  }
  .btn-style {
    float: right;
    width: auto;
    font-size: 12px;
    min-width: 150px;
    height: auto;
    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }
  .small {
    font-size: 100%;
  }
  .profileDiv {
    padding-bottom: 25px;
  }
  .disable-submit {
    opacity: 0.8;
    cursor: not-allowed !important;
    &:hover {
      opacity: 0.8;
      cursor: not-allowed !important;
    }
  }
  .help-block {
    color: red;
  }
  .sub-little-text {
    font-size: 12px;
  }

}

.confirmUserActiveContainer {
  .confirmButtonsContainer {
    width: 100%;
    padding: 10px 0 0 0;
    float: left;
    .image-section {
      height: 100%;
      overflow: scroll;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .confirmButtonsContainer.confirmationModalPopUp {
    height: 300px;
    padding: 20px 0;
  }
  .floating-preview-buttons {
    float: left;
    width: 30%;
    margin: 0 35%;
    position: absolute;
    bottom: -60px;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    .cross {
      position: relative;
      border: 3px solid #fff;
      height: 30px;
      width: 30px;
      right: -12px;
      top: -11px;
      border-radius: 25px;
      background: #fff;
      .fa {
        position: absolute;
        right: 3px;
        font-size: 22px;
        cursor: pointer;
        color: #a22e2a;
        font-style: normal;
      }
    }
  }
}

//KYC Document Upload SCSS
.upload-main-document {
  width: 100%;
  .upload-btn {
    height: 35px;
    background-color: #a22e2a;
    outline: none;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    min-width: 150px;
  }
  .upload-section {
    width: 100%;
    float: left;
    padding: 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    margin: 15px 0;
    .col-md-4 {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .personal-documents-section {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-bottom: 15px;
      .bottom-padding {
        padding-bottom: 20px;
      }
    }
  }
  .document-upload {
    i {
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
      color: #a22e2a;
    }
  }
  .file-upload-label {
    font-weight: 700;
    color: #374767;
    float: left;
    width: auto;
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0.8;
  }
  .file-upload {
    border: 2px dashed rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    height: 200px;
    margin: 0 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .file-upload-input {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }
    .drag-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      line-height: 0.8;
      i {
        font-size: 20px;
        color: #374767;
        margin-bottom: 10px;
      }
      h3 {
        font-weight: 700;
        color: #374767;
        float: left;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.8;
      }
    }
    .base-section {
      width: 100%;
      height: 100%;
      padding: 5px;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
      .close-btn {
        position: absolute;
        border: 3px solid #a22e2a;
        height: 23px;
        width: 23px;
        right: -12px;
        top: -11px;
        border-radius: 25px;
        i {
          position: absolute;
          right: 3px;
          font-size: 15px;
          cursor: pointer;
          color: #a22e2a;
        }
      }
      a {
        text-align: center;
        .update-file {
          .fa {
            margin-right: 5px;
            font-size: 18px;
            color: #a22e2a;
          }
        }
      }
    }
    .title {
      width: 100%;
      padding: 3px;
      text-align: center;
      border-top: 1px solid;
      border-color: rgba(0, 0, 0, 0.15);
      word-break: break-word;
      color: #374767;
      font-weight: bold;
    }
  }
}

//Existing User SCSS
.existing-user {
  font-size: 15px;
  min-width: 100%;
  overflow-y: hidden;
  .custyle {
    width: 100%;
    margin-top: 15px;
    .custab {
      width: 100%;
      .table-head {
        color: #0059b3;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        .head-row {
          font-weight: bold;
          text-align: left;
          height: 40px;
          line-height: 18px;
        }
        th {
          &:nth-child(2) {
            width: 160px;
          }
        }
      }
      .table-row {
        font-size: 12px;
        color: #0059b3;
        height: 40px;
      }
      .border-row {
        border: 1px solid #ddd;
        text-align: center;
        padding: 10px;

        .add-edit {
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
  .note-section {
    label {
      font-weight: 700;
      color: #a22e2a;
      float: left;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 14px;
      opacity: 0.9;
    }
  }
  .info-title {
    font-size: 13px;
  }
}

.message-section {
  label {
    font-weight: 700;
    color: #374767;
    float: left;
    width: 100%;
    margin: 0;
    font-size: 13px;
    opacity: 0.9;
    padding-top: 8px;
    padding-bottom: 3px;
  }
}

.info-error-title {
  margin-top: 5px;
  color: #a22e2a;
  font-size: 13px;
}

@media only screen and (min-device-width: 310px) and (max-device-width: 320px) {
  .profileForm {
    padding-right: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profileForm {
    .upload-main-document{
      .upload-section {
        .col-md-4 {
          &:last-child {
            padding-right: 15px;
          }
        }
      }
      .file-upload {
        .drag-text {
          h3 {
            margin: 0 20px;
          }
        }
      }
    }
  }
  .common-container {
    .input-button {
      display: flex;
      .btn-cancel-style {
        min-width: 120px;
        margin-right: 10px;
      }
    }
    .confirmUserActiveContainer {
      .confirmButtonsContainer {
        height: 250px;
      }
    }
  }
}

//section wise user profile
.user-image-div {
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
  //Farmer Profile Pic
  .user-profile-image-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    float: right;
    overflow: hidden;
    position: relative;
    border: 2px solid #374767;
    .user-image {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .imgUpload {
      display: none;
    }
    .fa-pencil {
      transition: .2s all;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      color: #fff;
      font-weight: bold;
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      text-align: center;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
    &:hover {
      .farmer-image {
        filter: brightness(80%);
      }
      .fa-pencil {
        opacity: 1;
        transform: scale(1.5);
      }
    }
  }
}

.relation-container {
  position: relative;
  .add-more {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #a22e2a;
    font-size: 16px;
    z-index: 5;
  }
}
.plus-add-more {
  font-size: 18px;
  float: right;
  padding-right: 15px;
  i {
    cursor: pointer;
  }
}
.add-more-text {
  width: 100%;
  padding: 0;
  color: #4d5a77;
  font-weight: bold;
  font-size: 13px;
  margin-right: 8px;
}
.upload-btn {
  -webkit-appearance: none;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: none;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 1rem;
  height: 2.6em;
  -webkit-box-pack: start;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(.375em - 1px);
  padding-left: calc(1.35em - 1px);
  padding-right: calc(2em - 1px);
  padding-top: calc(.375em - 1px);
  position: relative;
  vertical-align: top;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  color: #4a4a4a;
}
.csvUpload {
  display: none;
}
.checkbox-box-container {
  border: 2px dashed rgba(0, 0, 0, 0.15);
  position: relative;
  width: 50%;
  height: 120px;
  display: -webkit-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  cursor: pointer;
}
.uploaded-file-container {
  border: 2px dashed rgba(0, 0, 0, 0.15);
  position: relative;
  width: 50%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  img {
    width: 100%;
    float: left;
    cursor: pointer;
    border-radius: 5px;
    object-fit: contain;
    overflow: hidden;
  }
  .close-btn {
    position: absolute;
    border: 3px solid #a22e2a;
    height: 23px;
    width: 23px;
    right: -12px;
    top: -11px;
    border-radius: 25px;
    i.fa.fa-times {
      position: absolute;
      right: 3px;
      font-size: 15px;
      cursor: pointer;
      color: #a22e2a;
    }
  }
  .update-file {
    i {
      margin-right: 5px;
      font-size: 18px;
      color: #a22e2a;
    }
  }



}
.errorMessage {
  margin-left: 10px;
  color:#a22e2a
}
.approveMessage{
  margin-left: 10px;
  color: green;
}

.confirmUserLicenseActiveContainer {
  .approve-dl-image {
    width: 100%;
    height: 259px;
    border: 2px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserLicenseActiveContainer {
  @include confirmDialogueActiveInActive();
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}


.confirmButtonsContainer.confirmationModalPopUp {
  height: 300px;
  padding: 20px 0;
}
.floating-preview-buttons {
  float: left;
  width: 30%;
  margin: 0 35%;
  position: absolute;
  bottom: -60px;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  .cross {
    position: relative;
    border: 3px solid #fff;
    height: 30px;
    width: 30px;
    right: -12px;
    top: -11px;
    border-radius: 25px;
    background: #fff;
    .fa {
      position: absolute;
      right: 3px;
      font-size: 22px;
      cursor: pointer;
      color: #a22e2a;
      font-style: normal;
    }
  }
}

.confirmUserLicenseActiveContainer .modal-body{
  max-height:500px;
  overflow:scroll;
}


.img-view {

  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: initial !important;
    min-width: 300px !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }
}

.rejectImageModal-updated {
  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: 500px !important;
    min-width: 300px !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }

  ::ng-deep .nsm-body {
    padding-left: 10px;
  }
}

.approveImageModal-modal-updated {
  ::ng-deep .nsm-dialog{
    height: 93% !important;
    width: 60% !important;
    min-width: 300px !important;
  }

  ::ng-deep .nsm-content{
    height: inherit !important;
  }

  ::ng-deep .nsm-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}