 
@import "../../theme/sass/_auth";
@import "../../theme/sass/mixins";

$font-size: 13px;

.report-container {
  width: 96%;
  margin: 78px 2.5% 50px 2.5%;

  .header-container {
    width: 99%;
    float: left;
    border-radius: $border-radius;
    margin-top: 25px;

    .dashboard-data {
      width: 100%;
      overflow: auto;

      .my-vertical-rule {
        border: none;
        border-left: 1px solid #ccc;
        height: 100vh;
        float: left;
        margin-top: 45px;
        width: 1px;
      }

      .dashboard-details {
        width: 25%;
        min-height: 30vh;
        float: left;
        height: auto;

        .card-margin {
          padding-left: 3px;
        }

        .dashboard-details-title {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          padding: 15px 0;
        }

        .dashboard-cards-collection {
          display: inline-block;
          width: 100%;
          .card-margin {
            margin-top: 10px;
          }

          .dashboard-card {
            background-color: #FFF;
            padding: 15px 10px;
            border-radius: 5px;
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 118px;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

            .card-details {
              width: 70%;
              float: left;

              .card-name {
                font-size: 16px;
                line-height: 25px;
                word-wrap: break-word;
                text-align: center;
                color: $button-color;
              }

              .card-property {
                font-weight: 300;
                margin-top: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .card-date-stats {
                margin-top: 5px;
              }

              .card-stats {
                margin-top: 5px;
                font-size: 12px;
              }
            }

            .card-image {
              width: 30%;
              float: left;

              .img-outer-div {
                width: 65px;
                height: 65px;
                position: relative;

                img {
                  max-height: 100%;
                  max-width: 100%;
                  width: auto;
                  height: 80%;
                  position: absolute;
                }
              }

              .img-vert-algn {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }
            }
          }

          .dashboard-card-no-data {
            background-color: #FFF;
            padding: 15px 10px;
            border-radius: 5px;
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 118px;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

            .no-data {
              font-weight: 600;
              margin-top: 5px;
              text-align: center;
            }
          }

          .dashboard-card:hover {
            box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
          }

          .checking-in {
            border-left: 5px solid #33cc33;
          }

          .current-staying {
            border-left: 5px solid #00bfff;
          }

          .checking-out {
            border-left: 5px solid #ff0000;
          }

          .housekeeping {
            border-left: 5px solid #4d4d4d;
          }

          .cus-pointer {
            cursor: pointer;
          }
        }

        .left-space {
          padding-left: 3%;
        }

        .right-left-space {
          padding: 0 3%;
        }

        .right-space {
          padding-right: 3%;
        }
      }
    }
  }

  .modal-pop-up {
    @include confirmDialogueActiveInActive();
    .status-buttons {
      width: 100%;
      margin: 0 auto;

      .action-button {
        width: 100%;
        float: left;
        text-align: center;
        padding: 0 35%;

        .action-confirm {
          width: 95%;
          height: 35px;
          background-color: $button-color;
          border: none;
          font-size: $font-size;
          border-radius: $border-radius;
          color: white;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }

    .modal-header {
      padding: 20px 0;
      justify-content: center;
      background-color: #374767;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      color: #fff;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .booking-details {
      display: block;
      padding: 20px 10px 0 10px;
      width: 100%;
      float: left;

      .booking-info {
        display: block;
        padding-bottom: 5px;
        width: 100%;
        float: left;

        .booking-label {
          width: 50%;
          float: left;
          font-weight: bolder;
          padding-right: 20px;
          text-align: right;
        }

        .booking-data {
          width: 50%;
          float: left;
          padding-left: 20px;
          word-wrap: break-word;
        }
      }
    }

    .booking-logs {
      font-size: 12px;
      font-style: italic;
      display: block;
      float: right;
      width: 75%;
      padding: 5px 0;

      .logs-text {
        display: block;
        width: 100%;
        text-align: right;
        float: left;
        padding-bottom: 3px;

        .logs-label {
          display: inline-block;
          width: 30%;
          float: left;
        }

        .logs-data {
          display: inline-block;
          width: 70%;
          float: left;
        }
      }
    }
  }
}

.spraying-chart-icon {
  font-size: 25px;
  color: #000;
}

.machine-report-icon {
  width: 30px;
}

.modal-backdrop.fade {
  opacity: 0.6;
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .dashboard-container .header-container .dashboard-data .dashboard-details .dashboard-card .card-image .img-outer-div {
    width: 80px;
    height: 80px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1099px) {
  .dashboard-container .header-container .dashboard-data .dashboard-details {
    min-height: inherit;
  }
  .dashboard-container .header-container .dashboard-data .dashboard-details {
    width: 100%;
    margin-bottom: 15px;
  }
  .dashboard-container .header-container .dashboard-data .details-right-space {
    margin-right: 0;
  }
  .dashboard-container .header-container .dashboard-data .dashboard-details .dashboard-card .card-image .img-outer-div {
    width: 110px;
    height: 110px;
  }
  .dashboard-container .header-container .dashboard-data .details-left-space {
    margin-left: 0;
  }
  .dashboard-container .header-container .dashboard-data .dashboard-details .right-space {
    padding-right: 3px;
  }
  .dashboard-container .header-container .dashboard-data .dashboard-details .right-left-space {
    padding: 0 3px;
  }
  .dashboard-container .header-container .dashboard-data .dashboard-details .left-space {
    padding-left: 3px;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .dashboard-container .header-container .dashboard-action .date-container .label-date {
    display: none;
  }
  .dashboard-container .header-container .dashboard-action .date-container .mydp {
    width: 100% !important;
  }
  .dashboard-container .header-container .dashboard-action .navigation-container {
    margin: 0;
  }
}
