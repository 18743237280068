@mixin confirmButtons() {
  .confirmButtons{
    width:50%;
    margin:0 auto;
    .yesButton{
      width: 49%;
      float: left;
      .yesConfirm{
        width: 90%;
        height:36px;
        background: var(--updated-backgroud-linear-gradient) ;
        border-radius: var(--global-btn-radious);
        color: #ffff !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        line-height: 19px;
        outline: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    .noButton{
      width: 49%;
      float: left;
      .noConfirm{
        width:90%;
        height:36px;
        background: #FFFFFF !important;
        border: 1px solid var(--updated-primary-color) !important;
        border-radius: var(--global-btn-radious);
        color: var(--updated-primary-color) !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        line-height: 19px;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

@mixin placeholderColor($color...) {
  .searchbox::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  .searchbox::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  .searchbox:-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  .searchbox:-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

@mixin gridSearchInput() {
  width: 55%;
  float: left;
  padding: 5px 5px 5px 0px;
  .search {
    width: 100%;
    height: 35px;
    color:#666666;
    padding-left: 2%;
    border: 1px solid gainsboro;
    outline: none;
    box-shadow: none;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }
}

@mixin inputRightBorder($border-radius...) {
  .right-border {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

@mixin inputAddOnBorder($border-radius...) {
  .input-group-addon {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
}

@mixin addButtonContainer() {
  width: 50%;
  float: right;
  padding: 5px 0 5px 5px;
  .add {
    width: 70%;
    height: 35px;
    background-color: $button-color;
    outline: none;
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 15px;
    cursor: pointer;
    float: right;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }
}

@mixin uploadCancelButtonContainer() {
  width: 100%;
  height: 35px;
  background-color: #a22e2a;
  outline: none;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

@mixin buttonContainer () {
  display: block;
  width: 50%;
  float: left;
}

@mixin confirmDialogueActiveInActive() {
  .modal-footer{
    display:none;
  }
  .fade{
    opacity: 1 !important;
  }
  .confirmButtonsContainer{
    width:100%;
    height:50px;
    padding: 20px 0 0 0;
    @include confirmButtons();
    .remark-title {
      float:left;
      padding: 0 16px
    }
    .remark-box {
      resize: none;
      height: 100px !important;
      width: 94%;
      margin: 1px 1% 24px 16px;
    }
  }
  .confirmBookingsContainer{
    width:100%;
    height:50px;
    @include confirmButtons();
  }
  .modal-dialog {
    position: relative;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;

  }
  .modal-content {
    padding: 20px;
  }
  button.close {
    position: absolute;
    right: 0;
    padding-right: 10px;
    margin-top: -18px;
    cursor: pointer;
  }
  .modal-body {
    padding: 0 0 20px;
    top: 20px;
  }
}

.confirmUserLicenseActiveContainer .modal-body{
  max-height:500px;
  overflow:scroll;
}

@mixin confirmUserLicenseActiveContainer() {
  .modal-footer{
    display:none;
  }
  .fade{
    opacity: 1 !important;
  }
  .confirmButtonsContainer{
    width:100%;
    height:50px;
    padding: 20px 0 0 0;
    @include confirmButtons();
    .remark-title {
      float:left;
      padding: 0 16px
    }
    .remark-box {
      resize: none;
      height: 100px !important;
      width: 94%;
      margin: 1px 1% 24px 16px;
    }
  }
  .confirmBookingsContainer{
    width:100%;
    height:50px;
    @include confirmButtons();
  }
  .modal-dialog {
    position: relative;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;

  }
  .modal-content {
    padding: 20px;
  }
  button.close {
    position: absolute;
    right: 0;
    padding-right: 10px;
    margin-top: -18px;
    cursor: pointer;
  }
  .modal-body {
    padding: 0 0 20px;
    top: 20px;
  }
}
