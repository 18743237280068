@import "../../theme/sass/_auth";
@import "../../theme/sass/mixins.scss";

$font-size: 13px;

.user-tab-width {
  width: 33.33%;
}

.report-tab-width {
  width: 50%;
}

.width-25 {
  width: 25%;
}

.overflow-y-operator {
  overflow-y: -webkit-paged-x !important;
}

.booking-btn {
  background: #a22e2a;
}

.invalid-booking-btn {
  background: rgb(77, 90, 119);
}

.create-bookings-btn {
  color: #ffffff;
  min-width: 111px;
  margin-bottom: 5px;
}

.user-container {
  width: 96%;
  margin: 70px 1.5% 15px 1.5%;
  overflow-y: hidden;
  .user-grid-container {
    width: 99%;
    float: left;
    border-radius: $border-radius;
    margin-top: 30px;
    position: relative;
    .user-grid-action {
      padding: 0 15px;
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      overflow-y: auto;
      background: #fff;
      border-radius: $border-radius;
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
      .user-grid-search-container {
        width: 40%;
        float: left;
        padding: 1% 0;
        .user-grid-search-input {
          @include gridSearchInput();
          .input-fields {
            padding: 0.5rem 0.75rem;
            font-size: 14px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            line-height: 1.25;
          }
        }
        @include inputRightBorder($border-radius);
        .input-group {
          margin-bottom: 0;
          @include inputAddOnBorder($border-radius);
        }
        @include placeholderColor(#666, 0.7);
        .radio-user-role {
          margin-left: 10px;
          margin-top: 2%;
          float: left;
          @media screen and (max-width: 767px) {
            padding: 10px 0px;
            margin-left: 0;
          }
          .radio-margin {
            margin-right: 20px;
            .radio-label {
              text-align: center;
              padding-left: 5px;
              color: #666;
              position: relative;
              top: 2px;
            }
            .radio-button {
              margin: 0;
              font-size: 11px;
            }
          }
        }
      }
      .user-grid-action-container {
        width: 50%;
        float: right;
        padding: 1% 0;
        display: block;
        position: relative;
        .user-grid-action-add {
          @include addButtonContainer();
          .add-btn-width {
            width: 100%;
          }
        }
        .user-grid-action-choose-file {
          @include addButtonContainer();
          float: left;
        }
        .upload-btn-container {
          @include buttonContainer();
          .csvUpload {
            display: none;
          }
          .upload-btn {
            @include uploadCancelButtonContainer();
            i {
              margin-right: 5px;
            }
          }
        }
        .download-btn {
          width: 60%;
          height: 35px;
          background-color: #a22e2a;
          outline: none;
          border: none;
          border-radius: 3px;
          color: white;
          font-size: 15px;
          cursor: pointer;
          margin: 0 5px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          text-decoration: none;
          i {
            margin-right: 5px;
          }
        }
        .information-section {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-right: 0;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          .fa-info-icon {
            font-size: 18px;
            color: #374767;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      .section-grid-container {
        width: 60%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    .user-tab-grid-action {
      display: flex;
      align-items: center;
      .user-tab-section-grid-container {
        width: auto;
      }
      .user-tab-grid-search-container {
        width: 90%;
        display: flex;
        align-items: center;
        .user-tab-grid-search-input {
          width: auto;
        }
        .radio-user-role {
          margin-top: 0;
        }
      }
    }
    .user-grid-data-parent-container {
      min-height: 485px;
      background: #ffffff;
      overflow-y: auto;
      .date-pickers {
        display: block;
        float: left;
        position: relative;
        top: 13px;
        width:65%;
        .date-lable {
          position: relative;
          padding-top: 10px;
          left: 15px;
          width: 50px;
        }
        .salary-date-lable {
          left: 0;
        }
      }
      .map-date-pickers {
        display: block;
        float: left;
        line-height: 10px;
        margin: 10px 0;
        padding: 0 15px;
        .date-lable{}
      }
      .machine-tracking-date {
        display: block;
        float: left;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        line-height: 10px;
        margin: 15px;
        padding: 15px;
      }
    }
    .user-grid-template-parent-container {
      -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    }
    .user-grid-data-container {
      width: 100%;
      overflow-y: auto;
      background: #fff;
      border-radius: 2px;
      padding-top: 10px;

      .left-align {
        float: left;
        width: 100%;
        li {
          .left-align {}
        }
      }

      .main-campaign {
        padding-top: 20px;
        .panel {
          .panel-body {
            .wizard {
              min-width: 100%;
              float: left;
              background: #fff;
              margin: 0;
              border: 1px solid #E0E0E0;
              font-size: 14px;

              a {
                text-transform: uppercase;
              }

              @media screen and (max-width: 500px) {
                font-size: 8px;
              }
              @media screen and (max-width: 768px) {
                font-size: 12px;
              }
              .profile-tab {
                float: left;
                color: var(--updated-primary-color);
                text-align: center;
                border-right: 1px solid #E0E0E0;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                border-bottom: 1px solid #E0E0E0;
                &:hover {
                  background: #ddd9;
                }
                &:last-child {
                  border-right: none;
                }
                a {
                  text-transform: uppercase;
                  .profile-icon {
                    margin-right: 10px;
                    color: var(--updated-primary-color)
                  }
                }
              }
              .level1-profile-tab {
                width: 50%;
                float: left;
                text-align: center;
                border-right: 1px solid #E0E0E0;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                &:hover {
                  background: #ddd9;
                }
                &:last-child {
                  border-right: none;
                }
                a {
                  text-transform: uppercase;
                  .profile-icon {
                    margin-right: 10px;
                    color: #195C94;
                  }
                }
              }
              .housekeeper-profile-tab {
                width: 100%;
                float: left;
                text-align: center;
                border-right: 1px solid #E0E0E0;
                height: 50px;
                line-height: 50px;
                &:last-child {
                  border-right: none;
                }
                a {
                  text-transform: uppercase;
                  .profile-icon {
                    margin-right: 10px;
                    color: #195C94;
                  }
                }
              }

              .operator-tab {
                display: flex;
                align-items: center;
                position: relative;
                a {
                  width: 100%;
                  color: var(--updated-primary-color);
                }
                i.fa-filter {
                  margin-right: 10px;
                  cursor: pointer;
                  font-size: 18px;
                }
                #user-filters {
                  h2 {
                    font-weight: 700;
                    color: black;
                    float: left;
                    font-size: 15px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    padding: 15px 20px;
                    text-align: left;
                  }
                }
                .user-filter-container {
                  position: absolute;
                  top: 44px;
                  right: initial;
                  float: right;
                  min-width: 430px;
                  margin: 2px 0 0;
                  list-style: none;
                  background-color: #ffffff;
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  border-radius: 5px;
                  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
                  background-clip: padding-box;
                  border-top-left-radius: 0;
                  border-top-right-radius: 0;
                  @media only screen and (max-width: 767px) {
                    min-width: 330px;
                  }
                  &:before {
                    content: '';
                    display: inline-block;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #ccc;
                    border-bottom-color: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    top: -8px;
                    right: 24%;
                    @media only screen and (max-width: 767px) {
                      right: 3%;
                    }
                  }
                  .reset-filter-section {
                    float: left;
                    width: 100%;
                    border-bottom: 1px solid #ebebeb;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: default;
                    .filter-title {
                      font-weight: 700;
                      color: #4d5a77;
                      float: left;
                      font-size: 16px;
                      text-transform: uppercase;
                      margin-bottom: 15px;
                      cursor: initial;
                    }
                    .reset-icon {
                      width: auto;
                      padding: 0 20px;
                      cursor: pointer;
                      .dark-blue-text {
                        font-size: 18px;
                      }
                    }
                  }
                  .radio-user-role {
                    float: left;
                    width: 100%;
                    color: #666;
                    padding: 5px 20px;
                    border-bottom: 1px solid #ebebeb;
                    @media only screen and (max-width: 767px) {
                      padding: 5px 0 5px 15px;
                    }
                    .radio-margin {
                      float: left;
                      width: 100%;
                      text-align: left;
                      .filter-label {
                        font-weight: 500;
                        color: #374767;
                        font-size: 15px;
                        margin-bottom: 0;
                        width: 35%;
                        float: left;
                        margin-right: 0;
                        @media only screen and (max-width: 767px) {
                          width: 34%;
                          position: absolute;
                        }
                      }
                      .filter-container {
                        width: 65%;
                        float: right;
                        [type="radio"]:checked,
                        [type="radio"]:not(:checked) {
                          position: absolute;
                          left: -9999px;
                        }
                        [type="radio"]:checked + label,
                        [type="radio"]:not(:checked) + label
                        {
                          position: relative;
                          padding-left: 25px;
                          cursor: pointer;
                          line-height: 20px;
                          display: inline-block;
                          color: #666;
                          @media only screen and (max-width: 767px) {
                            margin-right: 0;
                            margin-left: 15px;
                          }
                        }
                        [type="radio"]:checked + label:before,
                        [type="radio"]:not(:checked) + label:before {
                          content: '';
                          position: absolute;
                          left: 0;
                          top: 0;
                          width: 19px;
                          height: 19px;
                          border: 1px solid;
                          border-radius: 100%;
                          background: #fff;
                          opacity: 0.5;
                        }
                        [type="radio"]:checked + label:after,
                        [type="radio"]:not(:checked) + label:after {
                          content: '';
                          width: 11px;
                          height: 11px;
                          background: #a22e2a;
                          position: absolute;
                          top: 4px;
                          left: 4px;
                          border-radius: 100%;
                          -webkit-transition: all 0.2s ease;
                          transition: all 0.2s ease;
                        }
                        [type="radio"]:not(:checked) + label:after {
                          opacity: 0;
                          -webkit-transform: scale(0);
                          transform: scale(0);
                        }
                        [type="radio"]:checked + label:after {
                          opacity: 0.9;
                          -webkit-transform: scale(0.9);
                          transform: scale(0.9);
                        }
                      }
                      .radio-label {
                        margin: 0 15px 0 5px;
                        &:first-child {
                          margin-right: 20px;
                        }
                      }
                    }
                  }
                  .filter-button-section {
                    float: left;
                    width: 100%;
                    padding: 20px;
                    cursor: initial;
                    @media only screen and (max-width: 767px) {
                      padding: 10px;
                    }
                    .btn-style-fixed {
                      width: 100px;
                      height: 35px;
                      background-color: #a9abad;
                      color: white;
                      font-size: 15px;
                      border: none;
                      border-radius: 3px;
                      cursor: pointer;
                      line-height: 1;
                    }
                    .filter-btn {
                      background-color: #a22e2a;
                    }
                    .disable-btn {
                      cursor: default;
                      opacity: 0.5;
                    }
                    .error-message {
                      color: red;
                      margin-top: -20px;
                      text-align: left;
                    }
                  }
                }
              }
              
            }
            .current-tab {
              font-size: 14px;
              border-bottom: 3px solid var(--updated-primary-color) !important;
              @media screen and (max-width: 500px) {
                font-size: 8px !important;;
              }
              @media screen and (max-width: 768px) {
                font-size: 12px !important;;
              }
            }
          }
        }
      }
      .user-table {
        font-size: 15px;
        min-width: 100%;
        overflow-y: hidden;
        .custyle {
          width: 100%;
          margin-top: 15px;
          .custab {
            width: 100%;
            .table-head {
              color: var(--table-font-primary-color);
              font-size: 13px;
              font-weight: bold;
              text-align: left;
              .head-row {
                font-weight: 600;
                text-align: left;
                height: 40px;
                line-height: 20px;
              }
              th {
                &:nth-child(2) {
                  width: 160px;
                }
              }
              .machine-head-row {
                th {
                  &:nth-child(4) {
                    width: 150px;
                  }
                }
              }
            }
            .machine-row {
              td {
                &:nth-child(4) {
                  text-decoration: underline;
                  cursor: pointer;
                  &:hover {
                    font-weight: bold;
                  }
                }
              }
            }
          }
          h2 {
            font-weight: 700;
            color: var(--header-title-color);
            float: left;
            padding: 0;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 15px;
          }
        }
        .table-row-client {
          font-size: 12px;
          color: #0059b3;
          height: 40px;
          cursor: pointer;
          &:hover {
            background: rgba(4, 160, 222, 0.23);
          }
        }
        .table-row {
          font-size: 13px;
          color: var(--table-font-primary-color);
          height: 40px;
          font-weight: 400;
        }
        .border-row {
          text-align: center;
          padding: 10px;
          .add-edit {
            margin: 0 5px;
            cursor: pointer;
            font-size: 16px;
            color: var(--updated-primary-color);
            .fa-trash {
              font-size: 16px;
              color: #e14335;
            }
            .fa-window-close-o {
              font-size: 16px;
              color: #e14335;
            }
            .fa-check-square {
              font-size: 16px;
              color: #7ab675;
            }
          }
          .show-cursor {
            cursor: pointer;
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
        .min-action-width {
          min-width: 110px;
        }
        .align-text-left {
          text-align: left;
          max-width: 150px;
          word-wrap: break-word;
        }
      }
      .user-template-container {
        padding: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .template-message {
          display: block;
          margin-right: 10px;
          width: 71%;
          padding: 10px 0;
          float: left;
        }
        .template-download-button {
          width: 18%;
          display: block;
          float: right;
            a {
              color: #a22e2a;
              &:hover, &:active, &:focus, &:visited {
                color: #a22e2a;
            }
          }
        }
      }
      .user-template-upload-error-container {
        padding: 20px 20px 0;
        .alert-danger {
          background-color: #fff;
          border-color: #fff;
          color: #a94442;
          padding: 15px;
          h2.element-invisible {
            color: red;
            padding: 0;
            font-size: 14px;
            margin-bottom: 15px;
            line-height: 24px;
            .alert-success {
              background: transparent;
              margin: 10px 0;
            }
          }
          ul {
            .operator-block {
              margin-bottom: 20px;
            }
          }
          table {
            width: 100%;
            max-width: 100%;
            tr {
              th.summary-title {
                font-weight: bold;
                height: 40px;
                line-height: 18px;
                border: 1px solid #ddd;
                text-align: center;
                color: #0059b3;
                font-size: 13px;
                padding: 10px;
                //width: 50%;
                &:first-child {
                  width: 30px;
                }
                &:nth-child(2) {
                  width: 180px;
                }
                &:nth-child(3) {
                  width: 180px;
                }
                &:nth-child(4) {
                  width: 120px;
                }
                &:nth-child(5) {
                  width: 100px;
                }
              }
            }
            tr {
              td.summary-count {
                max-width: 100px;
                word-wrap: break-word;
                border: 1px solid #ddd;
                text-align: center;
                font-size: 12px;
                color: #a22e2a;
                font-weight: bold;
                padding: 10px;
                .error-summary-count {
                  line-height: 2;
                }
              }
            }
          }
          .cancel-btn-container {
            width: 100%;
            float: left;
            margin: 20px 0;
            .cancel-btn {
              background-color: #a9abad;
              color: white;
              font-weight: bold;
              border: none;
              border-radius: 3px;
              cursor: pointer;
              width: auto;
              font-size: 12px;
              min-width: 150px;
              height: 35px;
              text-transform: uppercase;
            }
          }
        }
      }
      .user-template-notes-container {
        h2 {
          font-weight: 700;
          color: #4d5a77;
          float: left;
          padding: 0;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        ol {
          width: 100%;
          float: left;
        }
      }
      .upload-cancel-button-container {
        padding: 0 20px 20px;
        float: left;
        width: 100%;
        .download-btn-container {
          @include buttonContainer();
          text-align: center;
          a {
            width: 60%;
            height: 35px;
            background-color: #a22e2a;
            outline: none;
            border: none;
            border-radius: 3px;
            color: white;
            font-size: 15px;
            cursor: pointer;
            float: left;
            padding: 1px 6px;
            line-height: 36px;
            text-decoration: none;
            i {
              margin-right: 5px;
            }
          }
        }
        .cancel-btn-container {
          @include buttonContainer();
          .cancel-btn {
            @include uploadCancelButtonContainer();
            background-color: #a9abad;
          }
        }
      }
      .sample-template-data-section {
        width: 100%;
        padding: 15px;
        float: left;
        margin-top: 10px;
        h2 {
          font-weight: 700;
          color: #4d5a77;
          float: left;
          padding: 0;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        table {
          width: 100%;
          max-width: 100%;
          thead {
            tr {
              th {
                font-weight: bold;
                height: 40px;
                line-height: 18px;
                border: 1px solid #ddd;
                text-align: center;
                padding: 10px;
                color: #0059b3;
                font-size: 13px;
              }
            }
          }
          tbody {
            tr {
              td {
                max-width: 100px;
                word-wrap: break-word;
                border: 1px solid #ddd;
                text-align: center;
                padding: 10px;
                font-size: 12px;
                color: #a22e2a;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .user-template-notes-container {
      padding: 20px;
      position: absolute;
      top: 60px;
      right: 24.5%;
      float: right;
      width: 45%;
      margin: 2px 0 0;
      list-style: none;
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      background-clip: padding-box;
      z-index: 1;
      &:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: -8px;
        right: calc(100% - 50% / 2);
      }
      h2 {
        font-weight: 700;
        color: #4d5a77;
        float: left;
        padding: 0;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      .important-notes {
        font-size: 14px;
        width: 100%;
        float: left;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      }
    }
  }
}

.left-note-align {
  float: left;
  width: 100%;
}

.no-decoration {
  text-decoration:none ;
}

.tractor-icon {
  padding-right: 5px;
  img {
    height: 20px;
    width: 22px;
    margin-bottom: 4px;
  }
}

.machine-map-icon {
  width: 16px;
  height: inherit;
  border:0;
  cursor: pointer;
}

.location-dates {
  .selector {
    z-index: 1 !important;
  }
}

.confirmUserActiveContainer {
  @include confirmDialogueActiveInActive();
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserActiveContainer .fade {
  opacity: 1 !important;
}

.disable-submit {
  opacity: 0.8;
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.8;
    cursor: not-allowed !important;
  }
}

.summary-report-parent-container {
  width: 100%;
  padding: 15px;
  float: left;
  margin-top: 10px;
  h2 {
    font-weight: 700;
    color: #4d5a77;
    float: left;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .summary-title {
    font-weight: bold;
    height: 40px;
    line-height: 18px;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    color: #0059b3;
    font-size: 13px;
  }
  .summary-count {
    max-width: 100px;
    word-wrap: break-word;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #a22e2a;
    font-weight: bold;
  }
}

.unimart-common-menu {
  .nav.nav-tabs a:hover {
    cursor: pointer;
  }
  .nav-tabs, .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff;
  }
  .nav.nav-tabs a {
    color: var(--updated-primary-color) !important;
    padding: 1em;
  }
  .nav.nav-tabs {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff !important;
    background-color: var(--updated-primary-color) !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 575px) {
  .user-container {
    .user-grid-container {
      .user-grid-action {
        .user-grid-search-container {
          width: 100%;
          .user-grid-search-input {
            width: 100%;
            .input-group-addon {
              padding: 0.2rem 0.50rem;
              margin-bottom: 0;
              font-size: 1rem;
            }
          }
        }
        .user-grid-action-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          .userGridExport {
            width: 40%;
          }
          .user-grid-action-add {
            width: 50%;
            padding: 0;
            margin-top: 5px;
          }
          .information-section {
            padding: 10px;
          }
          .download-btn {
            height: 45px;
            margin-bottom: 10px;
          }
          .upload-btn-container {
            width: 60%;
            margin-bottom: 5px;
            .upload-btn {
              height: 45px;
            }
          }
          .user-grid-action-add {
            width: 60%;
          }
        }
      }
      .user-tab-grid-action {
        display: block;
        .user-tab-grid-search-container {
          width: 100%;
          .user-grid-search-input {
            width: 56%;
            margin-right: 5px;
          }
          .radio-user-role {
            .radio-margin {
              margin-right: 10px;
            }
          }
        }
        /*.user-tab-grid-search-container {
          width: 90%;
        }
        .user-grid-action-container {
          width: auto;
        }*/
      }
      .user-grid-template-parent-container {
        .user-grid-data-container {
          .upload-cancel-button-container {
            padding: 0 10px 20px;
            float: left;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            .download-btn-container {
              width: 100%;
              margin-bottom: 20px;
            }
            .upload-btn-container {
              width: 100%;
              margin-bottom: 20px;
            }
            .cancel-btn-container {
              width: 100%;
            }
          }
        }
      }
      .user-grid-data-parent-container {
        .user-grid-data-container {
          .main-campaign {
            .panel {
              .panel-body {
                .wizard {
                  .user-tab-width {
                    width: 100%;
                    border-bottom: 1px solid #E0E0E0;
                  }
                  .current-tab {
                    border-bottom: 3px solid #a22e2a;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .user-container {
    .user-grid-container {
      .user-grid-action {
        .user-grid-search-container {
          width: 45%;
          .user-grid-search-input {
            width: 80%;
          }
        }
        .user-grid-action-container {
          width: 55%;
          .userGridExport {
            width: 50%;
          }
          .user-grid-action-add {
            width: 50%;
          }
        }
      }
      .user-tab-grid-action {
        .user-tab-grid-search-container {
          .user-tab-grid-search-input {
            width: 50%;
            .input-fields {
              height: 35px;
            }
          }
        }
        .user-tab-section-grid-container {
          .btn-style-fixed {
            width: 100px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .user-container {
    .user-grid-container {
      .user-grid-data-parent-container {
        .summary-report-parent-container {
          .summary-report-section {
            font-size: 15px;
            min-width: 100%;
            overflow-y: hidden;
          }
        }
      }
      .user-grid-action {
        .user-grid-action-container {
          width: 100%;
        }
      }
      .user-tab-grid-action {
        .user-tab-grid-search-container {
          width: 100%;
        }
        .user-grid-action-container {
          width: auto;
        }
      }
      .user-grid-template-parent-container {
        .user-grid-data-container {
          .upload-cancel-button-container {
            .download-btn-container {
              display: flex;
              a {
                width: 90%;
              }
            }
            .upload-btn-container {
              .upload-btn {
                width: 90%;
              }
            }
            .cancel-btn-container {
              .cancel-btn {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
}

.confirmUserActiveContainer {
  @include confirmDialogueActiveInActive();
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserActiveContainer .fade {
  opacity: 1 !important;
}

.disable-submit {
  opacity: 0.8;
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.8;
    cursor: not-allowed !important;
  }
}
.user-name {
  float: left;
  width: 80%;
}
.user-count {
  span {
    min-width: 10px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: var(--updated-primary-color);
    border-radius: 50%;
    margin-left: 5px;

    &:hover {
      background-color: var(--updated-primary-color) !important;
    }
  }
}

.summary-report-parent-container {
  width: 100%;
  padding: 15px;
  float: left;
  margin-top: 10px;
  h2 {
    font-weight: 700;
    color: #4d5a77;
    float: left;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .summary-title {
    font-weight: bold;
    height: 40px;
    line-height: 18px;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    color: #0059b3;
    font-size: 13px;
  }
  .summary-count {
    max-width: 100px;
    word-wrap: break-word;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #a22e2a;
    font-weight: bold;
  }
}

.unimart-common-menu {
  .nav.nav-tabs a:hover {
    cursor: pointer;
  }
  .nav-tabs, .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff;
  }
  .nav.nav-tabs a {
    color: var(--updated-primary-color) !important;
    padding: 1em;
    font-weight: bold;
  }
  .nav.nav-tabs {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff !important;
    font-weight: bold;
    background-color: var(--updated-primary-color) !important;
  }
}
.switch-small{
  width: 28px !important;
  height: 15px !important;
  small {
    width: 15px !important;
    height: 15px !important;
  }
}
.approve-btn-style{
  background-color: green;
  color: white;
  margin-top: 18px;
  height: 36px !important;
  font-weight: bold;
  margin-left: 26px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: auto;
  font-size: 12px;
  min-width: 150px;
  height: auto;
}
.reject-btn-style{
  background-color: #a22e2a;
  color: white;
  margin-top: 18px;
  height: 36px !important;
  font-weight: bold;
  margin-left: 26px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: auto;
  font-size: 12px;
  min-width: 150px;
  height: auto;
}

.errorMessage {
  margin-left: 10px;
  color:#a22e2a !important;
}
.approveMessage{
  margin-left: 10px;
  color: green !important;
}

//approve-dl-image{
//  width: 100%;
//  height: 150px;
//}
//approve-dl-image img{
//  width: 100%;
//  height: 100%;
//  object-fit: contain;
//}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserLicenseActiveContainer {
  @include confirmDialogueActiveInActive();
}


.confirmUserLicenseActiveContainer {
  @include confirmDialogueActiveInActive();
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserLicenseActiveContainer .modal-body{
  max-height:500px;
  overflow:scroll;
}



.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}

.confirmUserLicenseActiveContainer {
  @include confirmDialogueActiveInActive();
}


.confirmUserLicenseActiveContainer {
  @include confirmDialogueActiveInActive();
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.confirmUserLicenseActiveContainer .fade {
  opacity: 1 !important;
}


.confirmButtonsContainer.confirmationModalPopUp {
  height: 300px;
  padding: 20px 0;
}
.floating-preview-buttons {
  float: left;
  width: 30%;
  margin: 0 35%;
  position: absolute;
  bottom: -60px;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  .cross {
    position: relative;
    border: 3px solid #fff;
    height: 30px;
    width: 30px;
    right: -12px;
    top: -11px;
    border-radius: 25px;
    background: #fff;
    .fa {
      position: absolute;
      right: 3px;
      font-size: 22px;
      cursor: pointer;
      color: #a22e2a;
      font-style: normal;
    }
  }
}

.users-confirmation-modal-updated  {
  .nsm-dialog{
    height: 50% !important;
    width: 40% !important;
    top: 0% !important;
  }

  .nsm-content{
    height: 100% !important;
  }

  .header-text {
    padding-left: 30px;
    margin-top: 20px;
  }
}