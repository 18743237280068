// Color of overlay background
$color-overlay: rgba(0, 0, 0, .5) !default;

// Position of dialog
$dialog-position-top: 1.75rem !default;
$dialog-position-bottom: 1.75rem !default;
$dialog-position-left: 1.75rem !default;
$dialog-position-right: 1.75rem !default;

// Transition time
// !! The same as the hideDelay variable defined in ngx-smart-modal.component.ts
$transition-duration: 500ms !default;

// Transition effect
// linear | ease | ease-in | ease-out | ease-in-out
$transition-timing-function: ease-in-out !default;

// Body overflow when a modal is opened.
// Set it to `auto` if you want to unlock the page scroll when a modal is opened
$opened-modal-body-overflow: hidden !default;

// Body if modal is opened
body.dialog-open {
  overflow: $opened-modal-body-overflow;
}

// Close button in modal
.nsm-dialog-btn-close {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color $transition-duration;
  background-color: transparent;
  z-index: 999;

  &.nsm-overlay-open {
    background-color: $color-overlay;
  }

  &.transparent {
    background-color: transparent;
  }
}

// Dialog modal
.nsm-dialog {
    opacity: 1;
    visibility: visible;
    pointer-events: none;
    outline: none;
    margin: 0 auto;
    position: relative;
    width: calc(100% - 10%);
    max-width: 100%;
    height: calc(100% - 40px);
    top: 20px;

  // When dialog is closing
  &.nsm-dialog-close {
    opacity: 0;
  }

  &.nsm-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
  }
}

.nsm-content {
  position: relative;
  display: flex;
  pointer-events: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  height: calc(100% - 40px);

  // For performance purpose
  // transform: translate3d(0, 0, 0);
}

.nsm-body {
  display: block;
  flex: 1 1 auto;
  top: 35px;
  bottom: 35px;
  width: 100%;
  overflow: auto;
}

/* *************************
* Animations
* *************************/

.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform $transition-duration $transition-timing-function, opacity $transition-duration;
}

// Left to right (ltr)
.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(-50%, 0, 0);
  }
}

// Right to left (ltr)
.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(50%, 0, 0);
  }
}

// Top to bottom (ttb)
.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, -50%, 0);
  }
}

// Bottom to top (btt)
.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, 50%, 0);
  }
}