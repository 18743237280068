 
@import "../../reports/reports.component.scss";

.no-padding-salary {
  padding: 0 !important;
}
.maring-bottom-salary {
  margin-bottom: 5px;
  margin-right: 15px;
}

.right-margin-salary {
  margin-right: 15px;
  min-width: 100px;
}
.edit-report-button {
  float: right;
  .right-margin-salary {
    float: right;
    margin-right: 0;
  }
  .update-report-button {
    margin-right: 10px;
  }
}

.filters-padding {
  .right-space {
    float: right;
    margin-right: 10px;
  }
  .upload-btn-container {
    margin-left: 10px;
    width: 150px;
    .csvUpload {
      display: none;
    }
    .upload-btn {
      width: 100%;
      height: 35px;
      background-color: #a22e2a;
      outline: none;
      border: none;
      border-radius: 3px;
      color: white;
      font-size: 15px;
      cursor: pointer;
    }
  }
}
.machine-report-edit {
  width: 30%;
}

.fo-manual-edit {
  float: left;
  display: inline-block;
  padding: 15px 0 15px
}

.user-container{
  .user-grid-container {
    .user-grid-data-container {
      .user-table {
        .back-office-table-grid {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          .table-body {
            display: block;
            overflow-y: auto;
            width: calc(25 * 120px);
            height: 320px;
            overflow-x: hidden;
            &::-webkit-scrollbar-thumb {
              background-color: #737272;
              border-radius: 10px;
            }
            .align-text-left {
              max-width: 120px;
              min-width: 120px;
            }
            .no-data {
              display: table;
              width: calc(25 * 120px);
            }
          }
          .fo-table-body {
            display: block;
            overflow-y: auto;
            width: calc(15 * 120px);
            height: 320px;
            overflow-x: hidden;
            &::-webkit-scrollbar-thumb {
              background-color: #737272;
              border-radius: 10px;
            }
            .align-text-left {
              max-width: 120px;
              min-width: 120px;
            }
            .no-data {
              display: table;
              width: calc(15 * 120px);
            }
          }
          .no-records {
            height: auto;
          }
          .table-head {
            tr {
              display: table;
              box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
              font-size: 13px;
              .head-row {
                max-width: 120px;
                min-width: 120px;
              }
            }
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: #737272;
          border-radius: 10px;
        }
      }
      //Excel Error Dto SCSS
      .alert.alert-danger {
        background: #fff;
        border-color: #fff;
        padding: 15px;
        .element-invisible {
          color: red;
          padding: 0;
          font-size: 14px;
          margin-bottom: 15px;
          line-height: 24px;
        }
        .note-disclamir {
          font-size: 13px;
          line-height: 24px;
        }
        .error-block {
          .summary-title {
            font-weight: bold;
            height: 40px;
            line-height: 18px;
            border: 1px solid #ddd;
            text-align: center;
            color: #0059b3;
            font-size: 13px;
            padding: 10px;
          }
          .max-width {
            width: 200px;
            max-width: 200px;
          }
          .summary-count {
            max-width: 100px;
            word-wrap: break-word;
            border: 1px solid #ddd;
            text-align: center;
            font-size: 12px;
            color: #a22e2a;
            font-weight: bold;
            padding: 10px;
          }
        }
        .cancel-btn-container {
          width: 100%;
          float: left;
          margin: 20px 0;
          .cancel-btn {
            background-color: #a9abad;
            color: white;
            font-weight: bold;
            border: none;
            border-radius: 3px;
            cursor: pointer;
            width: auto;
            font-size: 12px;
            min-width: 150px;
            height: 35px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .user-container {
    .user-grid-container {
      .user-grid-action {
        .machine-report-edit {
          width: 100%;
          .col-md-6 {
            width: 60%;
          }
        }
        .filters-padding {
          float: right;
        }
      }
      .user-grid-data-container {
        .user-table {
          .back-office-table-grid{
            .table-body {
              height: 435px;
            }
          }
        }
      }
    }
  }
  .btn-style-fixed {
    width: 120px;
  }
}

.img-view {

  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: initial !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }
}

.primary-font-color {
  color: var(--updated-primary-color);
}