 
 

$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

.farmer-main {
  background: rgba(214, 240, 252, 0.2);
  width: 100%;
  float: left;
  min-height: 100vh;
  .mobile-logo-section {
    background: #fff;
    text-align: center;
    padding: 20px 0;
    a {
      width: 35%;
      img {
        width: 35%;
      }
    }
  }
  .show-on-medium-device {
    display: none;
  }
  .header-logo {
    padding: 0 15px;
  }
  .header-container {
    float: left;
    width: 100%;
    height: 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 30px;
    .header-sub-container {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      float: left;
      height: 130px;
      .booking-section {
        display: flex;
        flex-direction: column;
      }
      .header-btn {
        position: absolute;
        bottom: 0;
        a {
          padding: 15px;
          border-radius: 45px;
          background: transparent;
          color: #666666;
          margin-right: 20px;
          text-decoration: none;
          cursor: pointer;
          font-weight: bold;
        }
      }

      //Misscall SCSS
      .misscall-section {
        background: #fff;
        width: 230px;
        padding: 6px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 10px;
        float: left;
        margin-bottom: 15px;
        .misscall-details {
          width: 90%;
          float: left;
          padding: 0 0 0 20px;
          p {
            margin-bottom: 0;
            font-size: 14px;
            color: #000;
            opacity: 0.85;
            font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }
          a {
            color: #000;
            opacity: 0.85;
            font-weight: bold;
            font-size: 16px;
            text-decoration: none;
            letter-spacing: 1px;
          }
        }
        .phone-icon {
          float: right;
          width: 20%;
          i {
            font-size: 35px;
            color: #666666;
          }
        }
      }
      .profile-inner {
        .view-farmer-profile {
          position: absolute;
          top: 120px;
          right: 0;
          float: right;
          min-width: 270px;
          width: 200px;
          margin: 2px 0 0;
          list-style: none;
          background-color: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          background-clip: padding-box;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          z-index: 2;
          padding: 15px;
          @media only screen and (max-width: 767px) {
            min-width: 200px;
            top: 83px;
            right: 5px;
          }
          &:before {
            content: '';
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: -8px;
            right: 15%;
            @media only screen and (max-width: 767px) {
              right: 10%;
            }
          }
          .profile-image {
            margin-bottom: 15px;
            .farmer-profile-section {
              margin: 0 0 0 10px;
              width: 150px;
              text-align: left;
              .farmer-name {
                font-size: 18px;
                font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
                font-weight: 700;
                margin-bottom: 0;
                margin-top: 10px;
              }
              p {
                &:last-child {
                  font-size: 14px;
                  font-weight: 500;
                  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
                }
              }
              span {
                font-size: 14px;
                font-weight: 500;
                font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
              }
            }
          }
          .edit-profile {
            a {
              cursor: pointer;
              margin-left: 5px;
              font-weight: 500;
            }
          }
        }
        .farmer-profile-section {
          margin: 0 10px;
          width: 150px;
          text-align: right;
          color: #000000;
          opacity: 0.85;
          .farmer-name {
            font-size: 18px;
            font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 10px;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }
        }
      }
    }
  }
  .farmer-land-details-section {
    padding: 0;
    //Farmer Tab
    .farmer-tabs-main-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: transparent;
      padding: 10px;
      border-radius: 12px;
      //cursor: pointer;
      position: relative;
      margin: 0 15px;
      min-height: 100px;
      justify-content: center;
      .farmer-tab-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: rgba(78, 101, 57, 0.9);
        padding: 10px;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        min-height: 60px;
        img {
          margin-right: 15px;
        }
        .tabs-title {
          font-size: 14px;
          color: #fff;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    //Weather Information CSS
    .weather-information-main-container {
      padding-bottom: 30px;
      .weather-place {
        color: #fff;
        text-align: center;
        margin-bottom: 0;
        height: 0;
        margin-top: 5px;
      }
      .weather-background-section {
        background-image: url("/assets/img/farmer/weather.jpg");
        background-attachment: initial;
        z-index: 2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        .no-weather-data {
          color: #fff;
          font-weight: bold;
          font-size: 15px;
          text-align: center;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
        .weather-info-section {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          align-items: center;
          padding: 25px 35px;
          .current-weather {
            .max-min {
              color: #fff;
              font-size: 23px;
              margin-right: 5px;
            }
            .unit {
              color: #ffffff;
              font-size: 12px;
              text-transform: lowercase;
            }
          }
          .title {
            color: #fff;
            font-size: 28px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
          }
          p {
            color: #fff;
            font-size: 16px;
            text-transform: uppercase;
            font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }
          .next-weather-info-section {
            width: 210px;
            .day-wise {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-pack: distribute;
              justify-content: space-around;
              .day-temp {
                color: #fff;
                font-size: 16px;
                margin-right: 10px;
                &:first-child {
                  width: 50px;
                }
              }
            }
          }
          .sun-cloud-section {
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            p {
              font-size: 14px;
              margin-left: 0;
              margin-bottom: 15px;

            }
            a {
              padding: 5px 10px;
              background: #4E6539;
              color: #fff;
              border-radius: 5px;
              opacity: 0.7;
              cursor: pointer;
            }
          }
        }
      }
    }

    //U Shaped CSS
    .farmer-all-report {
      margin: 0 15px 50px;
      display: flex;
      justify-content: center;
      .crop-soil-container {
        padding: 0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
        border-radius: 5px;
        background: #FEFEFE;
        .card-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          background: #C84148;
          padding: 10px;
          border-radius: 5px;
          color: #fff;
          font-weight: bold;
          margin-bottom: 0;
        }
        .card-content {
          width: 100%;
          padding: 15px 0;
          border-radius: 5px;
          margin: 0;
          min-height: 165px;
          .crop-image-section {
            text-align: center;
            img {
              margin-bottom: 5px;
            }
            span {
              font-weight: 700;
              margin-top: 5px;
            }
          }
          .crop-detail-section {
            p {
              font-size: 16px;
              font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
              font-weight: 500;
            }
            .no-bottom-margin {
              margin-bottom: 0;
            }
            i {
              margin-right: 5px;
            }
          }
        }
        .view-more-section {
          padding: 5px 10px 15px 10px;
          display: flex;
          justify-content: center;
          a {
            padding: 3px 20px;
            background: #4E6539;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
          }
          .no-opacity {
            opacity: 0;
            cursor: inherit;
            visibility: hidden;
          }
        }
        .hide-on-mobile {
          display: flex;
        }
        .show-on-mobile {
          display: none;
        }
      }
    }
    .land-maps {
      width: 100%;
      float: left;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      margin-bottom: 15px;
      .gmnoprint {
        display: none !important;
      }
    }
    .maps-section.last-child {
      width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      max-width: 100%;
      padding-right: 15px;
      .crop-details {
        .crop-image-section {
          .image-section {
            width: 15%;
          }
        }
      }
    }
  }

  //Farm Management
  .farm-management-container {
    background: #FFFFFF;
    padding: 30px;
    .back-section {
      margin-bottom: 20px;
      .farm-management-title {
        text-align: center;
        margin: 0 0 20px;
        font-size: 18px;
      }
    }
  }

  .single-farmer-details {
    .farmer-details {
      width: 100%;
      float: left;
      border-radius: 5px;
      padding: 20px;
      position: relative;
      .profile-labels {
        color: #4d5a77;
        font-weight: 700;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      }
      .profile-details {
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      }
      p {
        margin-bottom: 10px;
      }
      .edit-farmer {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
    .farmer-details.no-crops {
      min-height: 518px;
    }
  }
  .single-farmer-spraying-details {
    width: 100%;
    float: left;
    padding: 0 30px;
    margin-bottom: 40px;
    position: relative;
    .table-striped {
      width: 100%;
      .summary-title {
        font-weight: bold;
        height: 40px;
        line-height: 18px;
        border: 1px solid #ddd;
        text-align: center;
        padding: 10px;
        color: #0059b3;
        font-size: 13px;
      }
      .summary-count {
        max-width: 100px;
        word-wrap: break-word;
        border: 1px solid #ddd;
        text-align: center;
        padding: 10px;
        font-size: 12px;
        color: #0059b3;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }

    .summary-report-section {
      margin-top: 10px;
      .spraying-container {
        margin-bottom: 10px;
        min-height: 95px;
        .spraying-summary {
          width: 85%;
          background: #fff;
          padding: 15px 25px;
          display: flex;
          align-items: center;
          .spraying-details {
            width: 90%;
            p {
              font-size: 18px;
              color: #4d5a77;
              font-weight: bold;
              text-decoration: none;
              margin-bottom: 0;
            }
          }
          .price-section {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .amount {
              display: flex;
              flex-direction: column;
              align-items: center;
              i {
                font-size: 20px;
              }
              span {
                font-size: 24px;
                font-weight: bold;
              }
            }
          }
        }
        .image-section {
          width: 15%;
          height: 111px;
          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
      .map-marker-icon {
        margin-right: 5px;
        float: left;
        font-size: 20px;
        color: crimson;
        cursor: pointer;
      }
      .view-spraying-pattern {
        font-size: 14px;
        color: #4d5a77;
        font-weight: bold;
        margin-bottom: 0;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    //No Spraying Found
    .no-spraying-found {
      div {
        border: 1px solid rgb(169, 169, 169);
        padding: 10px;
        float: left;
        width: 100%;
        background: #FFF;
      }
    }
    .click-view {
      color: rgba(78, 101, 57, 0.9);
      font-size: 16px;
      font-weight: bold;
      &:hover {
        color: rgba(78, 101, 57, 0.9);
      }
    }

    //farmer meeting history SCSS
    .meeting-main-parent-container {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: space-between;
      .farmer-meeting-container {
        width: 49.5%;
        background: #fff;
        padding: 10px 25px;
        margin-bottom: 10px;
        .meeting-history {
          display: block;
          width: 100%;
          .meeting-type {
            width: 100%;
            float: left;
            span {
              float: left;
              width: auto;
              color: #4d5a77;
              font-weight: bold;
            }
            .location-time {
              width: 40%;
              float: right;
              i {
                margin-right: 5px;
                float: left;
                margin-top: 3px;
                font-size: 18px;
                color: crimson;
              }
            }
          }
          .user-title {
            width: 20%;;
            float: left;
            color: #4d5a77;
            font-weight: bold;
          }
          .user-details {
            width: 80%;
            float: left;
          }
        }
      }
    }
    .hide-on-small-device {
      display: block;
    }
    .hide-on-med-device {
      display: none;
    }
  }

  //iot Spraying pattern
  .machine-map-parent-container {
    agm-map {
      height: 400px;
    }
    .spraying-detail {
      margin-bottom: 5px;
      .spraying-text-title {
        font-weight: 500;
      }
      h3 {
        font-weight: bold;
        text-decoration: underline;
        text-align: center;
        font-size: 15px;
      }
    }
  }

  h2 {
    font-weight: 700;
    float: left;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 10px;
    opacity: 0.85;
    color: #000000;
    margin-top: 5px;
  }
  .header-title {
    margin-bottom: 0;
    margin-top: 5px;
  }
  .section-separator {
    position: absolute;
    top: -15px;
    left: 30px;
    width: 45px;
    height: 8px;
    background-color: #fd6224;
  }
  .farmer-land-maps {
    margin: 30px 0;
    padding: 0;
    float: left;
    width: 100%;
  }
  .no-farmer-details {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    padding: 0 30px;
    p {
      font-size: 15px;
      color: #a22e2a;
      font-weight: 500;
      font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      margin: 0;
    }
  }
  .summary-title {
    font-weight: bold;
    height: 40px;
    line-height: 18px;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    color: #0059b3;
    font-size: 13px;
    &:nth-child(6) {
      width: 140px;
    }
    &:nth-child(3) {
      width: 300px;
    }
  }
  .summary-count {
    max-width: 100px;
    word-wrap: break-word;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #0059b3;
  }
  .single-farmer-details-section {
    float: left;
    width: 100%;
    margin-bottom: 25px;
    padding: 0 30px;
    .table-striped {
      width: 100%;
    }
  }
  .ndvi-image-container {
    padding: 0 30px;
    h2 {
      margin: 20px 0 0 0;
    }
    .ndvi-inner-wrapper {
      margin-bottom: 20px;
    }
  }
  .farmer-profile-image-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    .farmer-image {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .ploy-maps {
    padding: 0 30px 40px 30px;
    position: relative;
    .save-land-section {
      margin: 10px 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: #fff;
      padding: 20px;
      .add-farmer-lands {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .add-btn {
          button {
            margin-top: 5px;
            float: left;
            width: auto;
            font-size: 13px;
            min-width: 120px;
            height: 35px;
            background-color: #a22e2a;
            color: #fff;
            font-weight: 700;
            border: none;
            border-radius: 3px;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
        .enter-land-name {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          label {
            margin-bottom: 0;
            margin-right: 10px;
          }
          input {
            width: auto;
          }
        }
      }
    }
    .header-title {
      .land-acres {
        font-size: 15px;
      }
    }
  }
  .display-none {
    display: none;
  }
  .display-block {
    display: block;
  }
  .preview-section {
    h2 {
      text-align: center;
      margin: 5px 0;
    }
    .image-section {
      img {
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }
  .main-campaign-booking-container {
    padding: 0 30px;
    @media only screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
}

//New Farmer CSS
.farmer-edit-form {
  .farmer-fields {
    margin: 10px 0;
    float: left;
    width: 100%;
    .form-control {
      width: 50%;
      float: left;
    }
    .error-message {
      clear: both;
      .help-block {
        color: red;
      }
    }
  }
  .profile-labels {
    float: left;
    margin-right: 10px;
  }
  .create-farmer-btn {
    float: right;
    width: auto;
    font-size: 13px;
    min-width: 120px;
    height: 35px;
    background-color: #a22e2a;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 0;
  }
}

//Farmer Feedback
.farmer-feedback-container {
  width: 100%;
  float: left;
  padding: 0 30px 30px 30px;
  margin-top: 5px;
  position: relative;
  h2 {
    font-weight: 700;
    float: left;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 10px;
    opacity: 0.85;
    color: #000000;
  }
  .add-feedback {
    textarea {
      height: 240px;
      width: 100%;
      resize: none;
      padding: 10px 35px 10px 10px;
    }
    .fa-microphone {
      font-size: 25px;
      position: absolute;
      right: 25px;
      padding: 10px;
      cursor: pointer;
    }
    .disableButton {
      cursor: not-allowed;
      opacity: 0.6;
    }
    .enable-speech-btn {
      color: #a22e2a;
      animation: circleScale 1s infinite;
    }
    @keyframes circleScale {
      0%, 100%   {transform: scale3d(1,1,1);}
      50%  {transform: scale3d(1.15,1.15,1.15);}
    }
  }
  .yesConfirm {
    margin-top: 15px;
    float: right;
    width: auto;
    font-size: 13px;
    min-width: 120px;
    height: 35px;
    background-color: #a22e2a;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.enable-speech-btn {
  color: #a22e2a;
  animation: circleScale 1s infinite;
}
@keyframes circleScale {
  0%, 100%   {transform: scale3d(1,1,1);}
  50%  {transform: scale3d(1.15,1.15,1.15);}
}

//Language SCSS
.language-main-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  position: relative;
  label {
    margin-bottom: 0;
    color: #666666;
    font-weight: bold;
  }
  .styled-select {
    width: 20%;
    select {
      background: #fff;
      font-size: 14px;
      height: 23px;
      padding: 0 5px;
      width: 90%;
      border: 2px solid #666666;
      outline: none;
      float: right;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  //Spinner SCSS
  .showbox {
    position: absolute;
    top: -10px;
    bottom: 0;
    right: 0;
    .loader {
      position: relative;
      margin: 0 auto;
      width: 35px;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        //top: -30px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        .path {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          stroke-linecap: round;
        }
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $red;
    }
    40% {
      stroke: $blue;
    }
    66% {
      stroke: $green;
    }
    80%,
    90% {
      stroke: $yellow;
    }
  }
}

.farmer-input-area {
  position: relative;
  textarea {
    padding: 10px;
    margin: 10px 0;
    resize: none;
    height: 170px;
  }
}

.command-title {
  float: left;
  width: 100%;
  font-family: inherit;
  font-size: 13px;
  font-weight: bold;
}
.add-margin {
  margin: 8px 0;
}
.searched-text {
  color: #4d5a77;
  font-weight: 700;
  padding: 0 5px;
}
.placeholder-content {
  font-size: 14px;
  font-weight: bold;
  color: #777;
  margin-right: 35px;
  float: right;
}
.products-main-list {
  color: #4d5a77;
  font-weight: 700;
}
.command {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  margin: 2px;
  color: #4d5a77;
  border: 1px solid #4d5a77;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  text-transform: capitalize;
  background: #fff;
}
.close-products {
  position: absolute;
  right: 10px;
  z-index: 10;
}
.searched-word {
  border-color: #bbc0c4;
  background-color: #FFF;
  color: #3b4045;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .searched-icon {
    padding: 10px;
    font-size: 20px;
  }
}

.disable-voice-input {
  opacity: 0.3;
  pointer-events:none
}

.help-main-section {
  .fa-microphone {
    font-size: 25px;
    margin-right: 15px;
    cursor: pointer;
    color: #fff;
  }
  .query-wrapper {
    width: auto;
    display: inline-block;
    margin-left: 10px;
    animation-duration: 7s;
    .fa-arrow-left {
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

.field-wrapper {
  position: relative;
  width: 100%;
  textarea {
    height: 130px;
    width: 100%;
  }
  i.fa-microphone {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 25px;
    cursor: pointer;
  }
}

.crop-health-title {
  word-break: break-word;
}

.confirmUserActiveContainer {
  .products-list-section {
    h2 {
      padding: 0 15px;
      text-transform: none;
    }
    .product-select-error {
      color: red;
      margin-top: 10px;
      padding: 0 15px;
      float: left;
      width: 100%;
    }
    .selected-products {
      margin: 15px 0;
      .product-list {
        height: 85px;
        overflow-y: scroll;
        li {
          padding: 3px 0;
        }
      }
    }
    .selected-products-wrapper {
      z-index: -1;
    }
    .confirmButtons {
      display: flex;
      width: 95%;
      float: left;
      margin: 20px 0;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      .yesButton, .noButton {
        width: 40%;
        text-align: center;
        .disable-booking-btn {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .confirm-button-wrapper {
      z-index: -1;
    }
    .create-booking-section {
      display: flex;
      align-items: center;
      float: left;
      .product-wrapper {
        width: 80%;
      }
      .image-container {
        padding-top: 26px;
        width: 20%;
        img {
          width: 90%;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            width: 90%;
            float: right;
          }
        }
        .scanner-container {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow: hidden;
          video {
            height: 100%;
          }
        }
      }
      .scanner-info-container {
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        right: 0;
        justify-content: flex-end;
        padding: 10px 0;
        .close-btn {
          font-size: 30px;
          color: white;
          cursor: pointer;
          margin-right: 15px;
        }
        .device-list {
          background-color: rgba(78, 101, 57, 0.9);
          color: white;
          padding: 7px;
          border: none;
          cursor: pointer;
          margin-right: 20px;
          font-size: 15px;
        }
      }
    }
  }
  .show-hide {
    min-height: 520px !important;
  }
  .close-products {
    .cross {
      position: relative;
      height: 30px;
      width: 30px;
      right: 0;
      top: 0;
      border-radius: 25px;
      background: #fff;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      .fa {
        font-size: 18px;
        cursor: pointer;
        color: #000;
        font-style: normal;
      }
    }
  }
}

.guid-tab:hover {
  width: 185px;
  height: 75px;
  border-radius: 0;
  padding: 10px 7px;
}

.farmer-picture {
  width: 70px;
  height: 70px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .farmer-main {
    padding: 0;
    .show-on-medium-device {
      display: block;
    }
    .hide-on-small-device {
      display: none;
    }
    .single-farmer-details {
      margin-bottom: 20px;
      padding: 0;
      .farmer-details {
        min-height: 285px;
      }
    }
    .single-farmer-spraying-details {
      padding: 0 15px;
      margin-top: 10px;
      .summary-report-section {
        min-width: 100%;
        overflow-y: hidden;
        .spraying-container {
          .spraying-summary {
            width: 70%;
            padding: 15px 25px 15px 10px;
            .spraying-details {
              width: 80%;
              p {
                font-size: 14px;
              }
            }
            .price-section{
              width: 20%;
              .amount{
                margin-bottom: 20px;
                span {
                  font-size: 16px;
                }
              }
            }
          }
          .image-section {
            width: 30%;
            height: 93px;
          }
          .pattern-image-section {
            min-height: 135px;
          }
        }
        .view-spraying-pattern {
          font-size: 13px;
        }
        .map-marker-icon {
          font-size: 16px;
        }
      }
      .meeting-main-parent-container{
        .farmer-meeting-container {
          width: 100%;
          padding: 15px;
          .meeting-history {
            .meeting-type {
              margin-bottom: 10px;
              .location-time {
                width: 50%;
              }
            }
            .user-details, .user-title {
              width: 50%;
            }
          }
        }
      }
      .hide-on-small-device {
        display: none;
      }
      .hide-on-med-device {
        display: block;
      }
    }
    .farmer-land-maps {
      padding: 0 15px;
      .col-md-6 {
        padding: 0;
      }
    }
    .single-farmer-details-section {
      padding: 0 15px;
      .summary-report-section {
        float: left;
        width: 100%;
        min-width: 100%;
        overflow-y: hidden;
      }
    }
    .no-farmer-details {
      margin: 20px 0 30px;
      padding: 0 15px;
    }
    .farmer-land-details-section {
      .col-md-6 {
        padding-right: 3px;
        &:nth-child(2) {
          flex: 1;
          max-width: 100%;
        }
      }
      .maps-section.last-child {
        padding-right: 0;
      }
      //Farmer Cards Media SCSS
      .farmer-all-report {
        margin: 0 5px 30px;
        justify-content: flex-start;
        .col-md-3 {
          padding-right: 7px;
          padding-left: 7px;
        }
        .crop-soil-container {
          margin-bottom: 15px;
          .card-title {
            font-size: 13px;
          }
          .card-content {
            min-height: 135px;
            .crop-image-section {
              img {
                width: 70%;
              }
            }
            .crop-detail-section {
              span {
                font-size: 11px;
              }
              p {
                font-size: 13px;
              }
            }
          }
          .view-more-section {
            padding: 0 10px 15px 10px;
            a {
              font-size: 13px;
            }
          }
          .hide-on-mobile {
            display: none;
          }
          .show-on-mobile {
            display: flex;
          }
        }
      }
    }
    .ndvi-image-container {
      padding: 0 15px;
    }

    //Farmer GUID
    .header-container {
      display: block;
      padding: 0;
      .header-sub-container {
        display: block;
        padding: 10px;
        .booking-section {
          display: inline-block;
          width: 48%;
          .misscall-section {
            width: 100%;
            .misscall-details {
              width: 87%;
              padding: 0 10px 0 5px;
              p {
                font-size: 12px;
              }
              a {
                font-size: 12px;
              }
            }
            .phone-icon {
              width: 10%;
            }
          }
        }
      }
      .profile-inner {
        width: 50%;
        display: block;
        position: relative;
        .farmer-profile-section {
          width: 115px !important;
          position: absolute;
          right: 55px;
          p {
            font-size: 12px !important;
          }
          .farmer-name {
            font-size: 15px !important;
            margin-top: 0 !important;
          }
        }
        .profile-image {
          .farmer-profile-section {
            right: 0;
            position: absolute;
          }
        }
        .farmer-profile-image-container {
          width: 55px;
          height: 55px;
        }
      }
    }
    .farmer-land-details-section {
      .farmer-tabs-main-container {
        margin: 0;
        .tabs {
          padding-right: 0;
          padding-left: 0;
        }
        .no-right-padding {
          padding-right: 0;
        }
        .farmer-tab-container {
          margin: 0 0 10px 0;
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          .tabs-title {
            font-size: 12px;
          }
        }
      }

      .weather-information-main-container {
        .weather-place {
          margin-top: 10px;
          font-size: 16px;
        }
        .weather-background-section {
          height: 200px;
          .weather-info-section {
            padding: 15px;
            height: 100%;
            .current-weather {
              display: inline-block;
              .title {
                font-size: 20px;
              }
              p {
                font-size: 12px;
                margin-bottom: 3px;
              }
              .max-min {
                font-size: 16px;
              }
            }
            .next-weather-info-section {
              width: auto;
              display: inline-block;
              margin: 0 25px;
              .day-wise {
                .day-temp {
                  font-size: 14px;
                }
              }
            }
            .sun-cloud-section {
              text-align: center;
              display: inline-block;
              width: 20%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .ploy-maps {
      padding: 0 15px 30px;
      .save-land-section {
        .add-farmer-lands {
          flex-flow: row wrap;
          .enter-land-name {
            margin-bottom: 20px;
            width: 100%;
            label{
              margin-bottom: 0;
              margin-right: 10px;
              width: 30%;
            }
            input {
              width: 70%;
            }
          }
          .add-btn {
            width: 100%;
            button {
              float: right;
              margin-top: 20px;
            }
          }
        }
      }
    }

    .add-geofence-section {
      padding: 0 15px 15px;
    }

    .farm-management-container {
      padding: 20px;
      .farmer-details {
        padding: 0;
        .basic-information-section, .land-information-section,
        .other-information-section, .farmer-geo-fence-section, .previous-crop-info-section {
          width: 100%;
          margin: 0;
        }
        .farmer-geo-fence-section {
          .farmer-geo-fence {
            width: 100%;
            margin-bottom: 30px;
          }
        }
        .previous-crop-info-section {
          .cust-tab {
            .border-row {
              min-width: 130px;
            }
          }
        }
        .col-md-6 {
          margin: 12px 0;
        }
      }
    }
    .ndvi-map-land {
      margin-top: 30px;
    }
    .section-separator {
      left: 15px;
    }

    //Languuage Main Contaiiner
    .language-main-container {
      margin: 0 0 8px 0;
      label {
        font-size: 13px;
      }
      .styled-select{
        width: 30%;
        select {
          font-size: 12px;
          height: 20px;
        }
      }

      //Spinner Media SCSS
      //Spinner SCSS
      .showbox {
        top: 0px;
        .loader {
          width: 20px;
        }
      }
    }
    .field-wrapper {
      .farmer-input-area{
        textarea {
          height: 100px;
        }
      }
      i.fa-microphone {
        right: 8px;
        top: 5px;
        font-size: 25px;
      }
    }
    .farmer-voice-title {
      font-size: 14px;
      margin-top: 0;
    }
    .command-title {
      font-size: 12px;
    }
    .command {
      padding: 0.28em 0.4em 0.1em;
      font-size: 80%;
    }
    .create-booking {
      font-size: 92%;
    }
    .placeholder-content {
      font-size: 12px;
      margin-right: 30px;
      margin-top: 2px;
    }
    .searched-word {
      min-height: 35px;
      padding: 5px;
    }
    .searched-text {
      font-size: 13px;
    }
  }
  .farmer-feedback-container {
    padding: 0 15px 30px 15px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1099px) {
  .farmer-main {
    .farmer-land-details-section{
      .farmer-all-report{
        .crop-soil-container{
          .card-content{
            .crop-image-section{
              img {
                width: 90%;
              }
            }
            .crop-detail-section {
              .crop-health-title {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .ploy-maps{
      .save-land-section {
        .add-farmer-lands {
          .enter-land-name {
            input {
              width: 60%;
            }
          }
          .crop-wrapper {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

#singleBooking {
  table {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    thead > tr {
      background: rgba(78, 101, 57, 0.9);
    }
    tbody > tr:nth-of-type(odd) {
      background-color: #fff;
    }
    .summary-title {
      font-weight: bold;
      height: 40px;
      line-height: 18px;
      border: none;
      text-align: center;
      padding: 10px;
      font-size: 13px;
      min-width: 150px;
      color: #fff;
    }
    .summary-count {
      max-width: 100px;
      word-wrap: break-word;
      border: none;
      text-align: center;
      padding: 10px;
      font-size: 12px;
      color: #666666;
      font-weight: 500;
    }
    .rejected-status {
      color: #a22e2a;
      font-weight: bold;
    }
    .approved-status {
      color: #7ab675;
      font-weight: 700;
    }
    .verified {
      color: #347c94;
      font-weight: bold;
    }
    .not-verified {
      color: #FB8C00;
      font-weight: bold;
    }
  }
  .summary-report-section {
    overflow: auto;
  }
}

.soil-health-grid-container {
  box-shadow: 0 0 40px 0 rgba(0,0,0,.15);
  tr {
    th {
      padding: 15px;
      font-size: 14px;
      width: calc(3 * 300px);
    }
    &:nth-child(even) {
      background-color: #f8f6ff;
    }
    td {
      background-color: transparent;
      padding: 15px;
      font-size: 14px;
      width: calc(3 * 300px);
    }
  }
  .table-head {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    tr {
      display: table;
      width: 100%;
    }
  }
  .table-body {
    display: block;
    overflow-y: auto;
    width: 100%;
    height: 400px;
    overflow-x: hidden;
  }
}

.img-view {

  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: initial !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }
}

.confirmation-modal-updated  {
  ::ng-deep .nsm-dialog{
    height: 25% !important;
    width: 40% !important;
    top: 0% !important;
  }

  ::ng-deep .nsm-content{
    height: 100% !important;
  }

  .header-text {
    text-align: center;
    margin-top: 20px;
  }
}