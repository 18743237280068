.machine-click {
  &:hover {
    background: #d9d9d9;
    text-decoration: underline;
  }
}

.user-container {
  .user-grid-container {
    .user-grid-action {
      .user-grid-search-container.search-wrapper {
        width: 25%;
        .user-grid-search-input {
          width: 75%;
        }
      }
      .user-grid-action-container.machine-wrapper-selector {
        width: 75%;
        .user-grid-action-add {
          width: 33.33%;
          .add {
            width: 90%;
          }
          .machine-breakdown-btn {
            width: 100%;
          }
        }
      }
    }
    .half-section-width {
      width: 50% !important;
    }
    .machine-bulk-upload {
      .add {
        height: 40px;
        background-color: #a22e2a;
        outline: none;
        border: none;
        color: white;
        font-size: 15px;
        cursor: pointer;
        float: right;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}

.error-container-section {
  .table-striped {
    border: 1px solid rgba(0,0,0,.15);
    margin: 15px 0 0;
    table-layout: fixed;
    .table-head {
      box-shadow: 0 2px 2px 2px rgba(0,0,0,.1);
      tr {
        display: table;
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,.1);
        font-size: 13px;
        width: 100%;
      }
    }
    .border-row {
      padding: .75rem;
      text-align: left;
      height: 55px;
      line-height: 18px;
      color: #285eb8;
      font-size: 13px;
      opacity: 1;
      font-weight: 500;
      max-width: 120px;
      min-width: 120px;
      span {
        color: #a22e2a;
        font-weight: 500;
      }
    }
    tbody {
      display: block;
      overflow-y: auto;
      width: 100%;
      height: 340px;
      overflow-x: hidden;
      tr {
        display: table;
        width: 100%;
        &:nth-child(odd) {
          background-color: rgba(0,0,0,.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .user-container {
    .user-grid-container {
      .user-grid-action {
        .user-grid-search-container {
          width: 100%;
          .user-grid-search-input {
            width: 100%;
          }
        }
        .user-grid-action-container {
          width: 100%;
          .user-grid-action-add {
            width: 100%;
            .add {
              width: 100%;
            }
            .machine-breakdown-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.user-filter-container {
  position: absolute;
  float: left;
  width: 397px;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  @media only screen and (max-width: 992px) {
    min-width: 330px !important;
  }
  @media only screen and (max-width: 922px) {
    width: 230px !important;
  }
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -8px;
    right: 2%;
    @media only screen and (max-width: 767px) {
      right: 3%;
    }
  }
  .reset-filter-section {
    float: left;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    .filter-title {
      font-weight: 700;
      color: #4d5a77;
      float: left;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-left: 15px;
      cursor: initial;
    }
    .reset-icon {
      width: auto;
      padding: 0 20px;
      cursor: pointer;
      .dark-blue-text {
        font-size: 18px;
      }
    }
  }
  .filter-button-section {
    float: left;
    width: 100%;
    padding: 20px;
    cursor: initial;
    @media only screen and (max-width: 767px) {
      padding: 10px;
    }
    .btn-style-fixed {
      width: 100px;
      height: 35px;
      background-color: #a9abad;
      color: white;
      font-size: 15px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      line-height: 1;
    }
    .filter-btn {
      background-color: #a22e2a;
    }
    .disable-btn {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .error-message {
      color: red;
      margin-top: -20px;
      text-align: left;
      padding: 15px 0;
    }
  }
}

.filter-style {
  float: right;
  cursor: pointer;
  font-size: 18px;
}

.date-label {
  float: left;
  padding-left: 2px;
  height: 35px;
}
.selectBillingType{
  width: 60%;
  padding-bottom: 17px;
  margin-top: 6px;
}

.saveBillingStatusButton{
  background-color: #a22e2a;
  width: 20%;
  height: 35px;
  border: none;
  font-size: 13px;
  border-radius: 3px;
  outline: 0;
  color: #fff;
  cursor: pointer;
  float: right;
  margin-top: 32px;
  margin-right: 10px;
}

.confirmation-modal-updated {
  .nsm-dialog{
    height: 25% !important;
    width: 40% !important;
    top: 0% !important;
  }

  .nsm-content{
    height: 100% !important;
  }

  .header-text {
    text-align: center;
    margin-top: 20px;
  }
}

.changeBillingTypeModal-updated {
  .nsm-dialog{
    height: 30% !important;
    width: 40% !important;
    top: 0% !important;
  }

  .nsm-content{
    height: 100% !important;
  }

  .header-text {
    padding-left: 15px;
    margin-top: 20px;
  }
}