 
 
 

.child-user-main-container {
  .child-user-container {
    float: left;
    width: 100%;
    margin: 20px 0;
    .background-ribbon {
      border-radius: var(--global-radious);
      padding-left: 5px !important;
      background: var(--updated-primary-color);
      color: #fff;
      .main-title {
        color: #fff;
      }
    }
    .pull-left {
      float: left;
      width: 100%;
      margin: 10px 0 20px;
      padding: 0;
    }
    .user-table {
      float: left;
      width: 100%;
      .current-user-details {
        float: left;
        width: 100%;
        margin-bottom: 20px;
        .user-name {
          font-size: 20px;
        }
        .text-color {
          //color: #4d5a77;
          color: #a22e2a;
          text-transform: uppercase;
        }
        h2 {
          margin-bottom: 5px;
        }
      }
      h2 {
        font-weight: 700;
        //color: #4d5a77;
        color: #a22e2a;
        float: left;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      .child-details-section {
        margin-top: 40px;
        float: left;
        width: 100%;
      }
      .employee-grid {
        float: left;
        width: 100%;
        overflow-y: hidden;
        .table-head {
          th {
            &:nth-child(2) {
              width: 160px;
            }
          }
        }
        .user-actions {
          display: table-cell;
          font-size: 15px;
          .add-edit {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
            .fa-window-close-o {
              color: #e14335;
            }
            i {
              cursor: pointer;
            }
            .fa-check-square {
              color: #7ab675;
            }
          }
        }
        .operator {
          min-width: 100px;
        }
      }
      .search-child-container {
        float: left;
        width: 100%;
        display: block;
        position: relative;
        margin-bottom: 20px;
        margin-top: 15px;
        .title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          h2 {
            margin-bottom: 0;
          }
        }
        .margin-bottom {
          margin-bottom: 15px;
        }
        .radio-user-role {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 15%;
          position: absolute;
          right: 260px;
          top: 6px;
          .radio-margin {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            input {
              margin-right: 5px;
            }
            .radio-label {
              margin-bottom: 0;
            }
          }
        }
        .search-box {
          width: auto;
          float: right;
        }
      }
      .tab-switch-container {
        width: 100%;
        min-width: 100%;
        float: left;
        background: #fff;
        margin: 0 0 20px;
        border: 1px solid #E0E0E0;
        font-size: 14px;
        margin-top: 15px;
        .profile-tab {
          float: left;
          text-align: center;
          border-right: 1px solid #E0E0E0;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          &:last-child {
            border-right: none;
          }
          .profile-icon {
            margin-right: 10px;
            color: var();
          }
        }
        .current-tab {
          font-size: 14px;
          border-bottom: 3px solid var(--updated-primary-color) !important;
          color: var(--updated-primary-color);
        }
      }
      .machine-table-data {
        width: 100%;
        float: left;
        .machine-tab-main {
          width: 100%;
          min-width: 100%;
          float: left;
          background: #fff;
          margin: 0 0 20px;
          border: 1px solid #E0E0E0;
          font-size: 14px;
          .machine-tab {
            float: left;
            text-align: center;
            border-right: 1px solid #E0E0E0;
            width: 50%;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            &:last-child {
              border-right: none;
            }
            .profile-icon {
              margin-right: 10px;
              color: var(--updated-primary-color);
            }
          }
          .current-tab {
            font-size: 14px;
            border-bottom: 3px solid var(--updated-primary-color);
            color: var(--updated-primary-color);
          }
        }
        .machine-list-grid {
          float: left;
          width: 100%;
          .assign-machine-grid {
            .machine-action {
              display: table-cell;
              font-size: 15px;
              text-align: center;
              .add-edit {
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
                .fa-trash {
                  color: #e14335;
                }
                i {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .search-box {
          margin-bottom: 20px;
          .input-group {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
          }
        }
      }
      .search-box {
        .user-grid-search-input {
          margin-bottom: 0;
          .input-group-addon {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
          }
          .right-border {
            padding: 0.5rem 0.75rem;
            font-size: 14px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            line-height: 1.25;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            outline: none;
            border: 1px solid gainsboro;
          }
        }
      }
      h2.main-title {
        font-weight: 700;
        color: #fff;
        float: left;
        padding: 0;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
    .media-wrapper {
      label {
        font-weight: 700;
        color: #4d5a77;
        float: left;
        padding: 0;
        margin-bottom: 5px;
        font-size: 12px;
        text-transform: uppercase;
      }

      .media-inner-wrapper {
        border: 1px solid rgba(0, 0, 0, .15);
        position: relative;
        width: 100%;
        height: 200px;
        margin: 0 0 30px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
          cursor: pointer;
        }

        i {
          margin-right: 5px;
          font-size: 18px;
          color: #a22e2a;
        }
      }
    }
    .user-details {
      margin: 5px 0 0;
    }
  }

  .operator-wrapper {
    display: flex;
    justify-content: space-between;
    .operator-profile-image-wrapper {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      float: right;
      overflow: hidden;
      position: relative;
      border: 2px solid #374767;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}

.child-user-main-container{
  .child-user-container {
    .user-table {
      h2.main-title {

      }
    }
  }
}

.family-container {
  display: flex;
  flex-flow: row wrap;
  .first-child {
    margin-right: 5px;
  }
  .card-body {
    display: flex;
  }
}
.parent-image-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  .image-container {
    border: 2px dashed #4d5a77;
    position: relative;
    width: 35%;
    height: 100px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 5px;
    float: right;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
}
.no-media {
  justify-content: flex-start;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .child-user-main-container {
    .child-user-container {
      .user-table {
        .search-child-container {
          display: block;
          .radio-user-role {
            display: block;
            width: 100%;
            position: relative;
            right: 0;
            margin: 10px 0;
            float: left;
            top: 0;
            .radio-margin {
              display: inline-block;
              margin-right: 15px;
            }
          }
          .search-box {
            float: left;
            width: 100%;
          }
        }
        .machine-tab, .profile-tab {
          padding: 3px;
          height: auto;
          min-height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          a {
            line-height: 20px;
          }
        }
        .assign-machine-grid {
          overflow-y: hidden;
          width: 100%;
          float: left;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .child-user-main-container {
    .child-user-container {
      .user-table {
        .search-child-container {
          .title {
            width: 35%;
            h2 {
              line-height: 18px;
            }
          }
          .radio-user-role {
            .radio-margin {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

.img-view {

  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: initial !important;
    min-width: 300px !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }
}