 

.insurance-container {
  .full-width {
    margin-bottom: 10px;
  }
  .insurance-from {
    padding: 0 10px 0 0;
  }
  .insurance-to {
    padding: 0 0 0 10px;
  }
}

.machine-details-container {
  .machine-basic-info, .machine-document-upload {
    width: 100%;
    float: left;
    padding: 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    margin: 15px 0;
    display: flex;
    flex-flow: row wrap;
  }
  .machine-document-upload {
    .file-upload-label, .other-documents-files {
      font-weight: 700;
      color: #374767;
      float: left;
      width: 100%;
      margin: 0 0 10px 0;
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      opacity: 0.8;
    }
    .other-documents-files {
      padding: 0 15px;
      margin-bottom: 20px;
    }
    .error-text-section {
      color: #a22e2a;
    }
    .file-upload {
      border: 2px dashed rgba(0, 0, 0, 0.15);
      position: relative;
      width: 100%;
      height: 200px;
      margin: 0 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .file-upload-input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }
      .drag-text {
        display: flex;
        align-items: center;
        flex-direction: column;
        line-height: 0.8;
        i {
          font-size: 20px;
          color: #374767;
          margin-bottom: 10px;
        }
        h3 {
          font-weight: 700;
          color: #374767;
          float: left;
          width: auto;
          margin: 0;
          padding: 0;
          font-size: 14px;
          text-transform: uppercase;
          opacity: 0.8;
        }
      }
      .base-section {
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
          cursor: pointer;
          z-index: 2;
        }
        a {
          text-align: center;
          z-index: 5;
        }
        .update-file {
          i {
            margin-right: 5px;
            font-size: 18px;
            color: #a22e2a;
          }
        }
        .close-btn {
          position: absolute;
          border: 3px solid #a22e2a;
          height: 23px;
          width: 23px;
          right: -12px;
          top: -11px;
          border-radius: 25px;
          z-index: 3;
          i {
            position: absolute;
            right: 3px;
            font-size: 15px;
            cursor: pointer;
            color: #a22e2a;
          }
        }
      }
    }
  }
}

.img-view {

  ::ng-deep .nsm-dialog{
    height: initial !important;
    width: initial !important;
  }

  ::ng-deep .nsm-content{
    height: initial !important;
  }
}