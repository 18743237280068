@import "../data-table/data-table.component";

$title-font-size: 16px;
$description-font-size: 14px;
$font-weight: 100;

.main-title {
  font-weight: 700;
  float: left;
  padding: 0;
  margin-bottom: 15px;
  font-size: $description-font-size;
  .title {
    font-weight: $font-weight;
    font-size: $description-font-size;
    word-break: break-word;
  }
  .status-title-label {
    font-weight: 500;
  }
}

.background-ribbon {
  background-color: var(--updated-primary-color);
}

.status-title {
  font-weight: 500;
}

.title-section {
  margin-bottom: 5px;
  font-weight: 700;
  color: #fff;;
  float: left;
  font-size: 14px;
  padding: 10px;
}

.scroll {
  overflow: auto;
}

.media-wrapper {
  .card {
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
    .fa-file-word-o, .fa-file-pdf-o, .fa-file-image-o, .fa-download {
      font-size: 18px;
      color: #4d5a77;
    }
  }
}

.header-title.background-ribbon {
  border-radius: var(--global-btn-radious);
  padding-left: 5px;
  background-color: var(--updated-primary-color) !important;
  color: #fff;
  .main-title {
    color: #fff;
  }
}

#fsModal, #handOverModal, #stateModal, #sprayingDetailModal, #sprayingEditModal, #sprayingImagesModal,
#expenseModal, #productImagesModal, #quotationModel, #selfieModal, #bookingMediaModal, #videoUploadModal {
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .modal-dialog {
    margin: 0 auto;
    position: relative;
    width: calc(100% - 10%);
    max-width: 100%;
    height: calc(100% - 40px);
    top: 20px;
  }

  .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
    background: rgba(129, 129, 129, 0.71);
    border: 0;
    .close-btn {
      background: #fff;
      color: red;
      font-size: 20px;
      padding: 6px;
      cursor: pointer;
    }
  }

  .modal-title {
    color: #fff;
    line-height: 15px;
    font-size: 15px;
  }

  .modal-body {
    position: absolute;
    top: 35px;
    bottom: 35px;
    width: 100%;
    overflow: auto;
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .card {
      min-height: 60px;
      img {
        object-fit: contain;
        max-width: 60%;
        width: 100%;
        max-height: 500px;
        margin: 0 auto;
      }
      .machine-status-image {
        width: auto;
      }
      .arrow-download {
        width: 120px;
        height: 35px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: #4d5a77;
        color: white;
        font-size: 15px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        i {
          margin-right: 10px;
        }
      }
    }
    .no-data {
      text-align: center;
      border: none;
    }
  }

  .no-data-found {
    display: flex;
    align-items: center;
  }

  .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 35px;
    background: #e6e6e6;
  }
  .modal-close {
    background: #fff;
    border: 1px solid gray;
  }

  .btn {
    border-radius: 0; &:focus,
    &:active,
    &:active:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .btn-default,
  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active {
    color: #fff;
    text-align: center;
    background: darken(#dcdfe4, 10%);
    border: 1px solid darken(#dcdfe4, 20%);
    border-bottom: 3px solid darken(#dcdfe4, 20%);
    &:active {
      border-bottom: 1px solid darken(#dcdfe4, 20%);
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background: #f1f3f5;
    border-left: 1px solid darken(#f1f3f5, 10%);
  }

  ::-webkit-scrollbar-thumb {
    background: darken(#f1f3f5, 20%);
  }

  /*Handover SCSS*/
  .timeline {
    position: relative;
    padding: 4px 0 0 0;
    margin-top: 22px;
    list-style: none;
  }
  .timeline-image {
    z-index: 100;
    position: absolute;
    left: 45%;
    border: 4px solid #a22e2a;
    border-radius: 100%;
    box-shadow: 0 0 10px #a22e2a;
    width: 140px;
    height: 140px;
    margin-left: -100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .timeline-inverted {
    .timeline-image {
      left: 60%;
    }
  }
  li.timeline-inverted .timeline-panel {
    float: right;
    padding: 20px 20px 0 20px;
    text-align: left;
  }
  .img-circle {
    font-weight: 700;
    color: #4d5a77;
    float: left;
    padding: 0;
    font-size: 15px;
  }
  .timeline-panel {
    position: relative;
    float: left;
    width: 35%;
    padding: 20px 20px 0 20px;
    text-align: right;
  }
  .timeline-heading h4 {
    margin-top: 22px;
    margin-bottom: 4px;
    padding: 0;
    color: #b3b3b3;
  }
  .subheading {
    font-weight: 700;
    color: #4d5a77 !important;
    float: left;
    padding: 0;
    font-size: 18px;
    margin-bottom: 15px !important;
  }

  //New SCSS
  ul.timeline {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 30px;
    .parent-main-list {
      margin-top: 100px;
      width: calc( 100% / 4 );
      &:nth-of-type(odd) {
        .content {
          transform: translateY(calc(-100% - 24px));
        }
      }
    }
    .year {
      color: #fff;
      background: #3f444c;
      display: block;
      position: relative;
      padding-left: 24px;
      line-height: 24px;
      font-weight: bold;
      font-size: 13px;
      &:before {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        background: #ffd402;
        border-radius: 50%;
        left: -24px;
        top: calc( (40 - 24) / 2 * -1px );
      }
    }
    .content {
      border-left: 8px solid #ffd402;
      margin-left: -8px;
      padding: 15px 0;
      padding-right: 24px;
      float: left;
      width: 100%;
      li {
        padding-left: 20px;
        padding-bottom: 5px;
        position: relative;
        margin-left: -8px;
        list-style: none;
        float: left;
        width: 100%;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #eef4f7;
          left: 2px;
          top: 8px;
        }
        &:last-of-type {
          border-bottom: 0;
          padding-bottom: 0;
        }
        h4 {
          margin-bottom: 0;
          line-height: inherit;
        }
      }
    }
  }
  .inspection-main-wrapper {
    padding: 20px;
    .card {
      border: none;
    }
    .background-ribbon-inspection {
      background: #4d5a77;
      color: #fff;
      font-size: 13px;
    }
    .main-title {
      line-height: inherit;
    }
  }
}

.parent-wrapper-container {
  float: left;
  width: 100%;
  position: relative;
  padding: 10px;
  .expense-filter-container {
    float: left;
    margin: 10px 0 20px;
    .expense-type, .expense-status {
      float: left;
    }
    .date-filter {
      float: right;
      .date-pickers {
        top: 30px;
        width: 100%;
      }
    }
  }
  .date-pickers {
    position: absolute;
    right: 0;
    display: flex;
    width: 52%;
    top: 50px;
    .from-to-date {
      display: flex;
      align-items: center;
      width: 33.33%;
      margin-left: 10px;
      .date-margin {
        width: auto;
        margin-right: 10px;
        margin-top: 0;
        margin-bottom: 0;
      }
      .date {
        width: 100%;
        margin: 0;
      }
      &:first-child {
        width: 37%;
      }
    }
    .filter {
      float: left;
      background: #a22e2a;
      font-size: 22px;
      text-align: center;
      border-radius: 3px;
      margin: 0 0 0 10px;
      color: #fff;
      padding: 10px;
      height: 35px;
      display: flex;
      cursor: pointer;
      i {
        font-size: 18px;
      }
    }
    .btn-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
  .head-row {
    font-weight: bold;
    text-align: left;
    height: 40px;
    line-height: 18px;
    font-size: 13px;
    color: #0059b3;
  }
}

.pending-status {
  color: rgba(78, 101, 57, 0.9);
  font-weight: bold;
}

.rejected-status {
  color: #a22e2a;
  font-weight: bold;
}

.accepted-status {
  color: #7ab675;
  font-weight: bold;
}

.job-check-point {
  height: 360px;
  overflow-y: auto;
  .isOk {
    color: #7ab675;
    font-weight: bold !important;
  }
  .isNotOk {
    color: #a22e2a;
    font-weight: bold !important;
  }
}

.spare-parts-wrapper {
  table {
    border-collapse: collapse;
    background: #fff;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    thead {
      tr {
        height: 50px;
        background: #4d5a77;
        th {
          color: #fff;
          line-height: 1.2;
          font-weight: unset;
        }
      }
    }
    tbody {
      tr {
        color: #4d5a77 !important;
        line-height: 1.2;
        font-size: 13px;
        font-weight: 500;
        height: 50px !important;
        i {
          color: #4d5a77;
          margin-right: 5px;
          font-weight: 500;
        }
        &:nth-child(odd) {
          background-color: #fff;
        }
        &:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .column1 {
    width: 260px;
    padding-left: 40px;
  }

  .column2 {
    width: 245px;
  }

  .column3 {
    width: 110px;
    text-align: right;
  }

  .column4 {
    width: 170px;
    text-align: right;
  }

  .column5 {
    width: 222px;
    text-align: right;
    padding-right: 62px;
  }
}

.main-title {
 .title {
   font-weight: 300;
   font-size: 14px;
   word-break: break-word;
   line-height: 1.4;
 }
}

.machine-main-selfie-container {
  .machine-selfie-conatiner {
    display: flex;
    flex-flow: row wrap;
    .bg-light {
      background-color: #f8f9fa;
    }
    .card {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 2px solid rgba(0, 0, 0, 0.125);
      border-radius: 5px;
      margin-bottom: 25px;
    }
    .card-image-section {
      height: 170px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-img-top {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
      }
      .zoom-icon {
        position: absolute;
        color: #fff;
        display: none;
        font-size: 24px;
      }
      &:hover {
        img {
          filter: brightness(0.5);
        }
        .zoom-icon {
          display: -webkit-box;
          display: flex;
        }
      }
    }
    .card-body {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0.75rem;
      .main-title {
        margin-bottom: 10px;
        font-size: 13px;
        .title {
          font-size: 13px;
          text-transform: capitalize;
        }
      }
      .card-title {
        margin-bottom: .75rem;
      }
      .border-bottom {
        border-bottom: 2px solid #dee2e6;
      }
    }
  }

  .show-more-btn-container {
    margin: 20px 0;
    .upload-btns {
      width: 15%;
      font-weight: bold;
      display: inherit;
      padding: 0 30px;
      height: 41px;
      line-height: 33px;
      border-radius: 40px;
      font-size: 15px;
      margin: 0 auto;
      outline: none;
      cursor: pointer;
      background: transparent;
      border: 2px solid #333;
      color: #000;
      min-height: 50px;
      &:hover {
        background: #4d5a77;
        color: #fff;
        border: 2px solid #4d5a77;
      }
    }
  }

  .date-pickers {
    width: 100%;
    padding: 10px;
  }
}
