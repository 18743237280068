@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg); /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99100311111;
  background: transparent;
  background-color: rgba(153, 153, 153, 0.43);
  & > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    // border-top-color: var(--updated-primary-color);
    transform: translate3d(0, 0, 0);
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      // border-top-color: var(--updated-primary-color);
      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 10px solid transparent;
      border-top-color: var(--updated-primary-color);
      animation: spin 0.6s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}


#preloaderContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99100311111;
  & > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $danger;
    transform: translate3d(0, 0, 0);
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $primary;
      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $warning;
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}
